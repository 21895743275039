/**
 * ==================== @ BREADCRUMB
*/

.breadcrumb {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.breadcrumb__list {
  list-style: none;
  padding: 0;
  li {
    display: inline-block;
    font-size: em(13);
    color: $c-grey;
    font-style: italic;
  }
  li + li::before {
    content: "|";
    display: inline-block;
    color: $c-grey;
    font-style: normal;
    margin: 0 2px 0 8px;
  }
  li:last-child {
    color: $c-gold;
    font-style: normal;
    font-weight: $fw-semibold;
  }
  li a {
    color: $c-grey;
  }
}

@media screen and (min-width:$bp-l) {

  .breadcrumb {
    margin-top: 0;
  }

}
