/**
 * ==================== @ GENERIC FLOW TOOLS
 */

.l-clearfix { @extend %clearfix; }

.l-wrapper {
	letter-spacing: .1px;
  margin: 0 auto;
  padding: 0 $layout-padding;
  position: relative;
  max-width: $bp-1280;
}

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.l-center {
	text-align: center;
}

.l-right {
	text-align: right;
}

/* --- EMBED --- */
.l-embed {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16.9 */
  height: 0;

  // &.m--rXXX {} // Ratio modifier
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page {
	min-height: 600px;
	overflow: hidden;
}

.entry.m--big p {
  font-size: em(20);
  margin-top: em(10);
}

.entry.m--blue p {
  color: $c-blue;
}

.entry__title {
  font-size: rem(33);
  margin: 0;
}

.bg-blue {
  background-color: $c-blue;
}

.bg-gold {
  background-color: $c-gold;
}

.bg-white {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 2rem);
    position: absolute;
    top: 2rem;
    right: 0;
    background-color: #FFF;
  }
}
