/**
 * ==================== @ Notre Histoire
*/

.history {

	.history-timeline {

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			color: $c-blue;
			font-size: em(16);

			em {
				font-style: normal;
				font-weight: $fw-semibold;
				display: inline-block;
				margin: 1em 0 .5em 0;
				font-size: em(20, 16);
			}

			strong {
				color: $c-gold;
			}
		}

	}

	.entry__title {
    color: $c-blue;
    font-size: em(20);
	}

}

@media screen and (min-width:$bp-l) {

.history {
  margin: 4em 0;
  padding-top: 4rem;

		.history-timeline {

			li {
				clear: both;
				float: left;
				max-width: 45%;
        line-height: 1.6;

				&:first-child, &:last-child {
					background-color: $c-grey-lite;
					float: none;
					font-size: em(20, 16);
					max-width: 50%;
					margin: 0 auto;
        	padding: .5em 0 1em 0;
					text-align: center;

					em {
       			color: $c-gold;
        		font-size: em(28, 16);
					}

					p {
						font-size: em(20);
        		line-height: 1.6;
					}
				}

				&:nth-child(2n+2) {
					float: right;
				}

			}

		}

   /* .entry {
      max-width: 45%;
      p {
        margin-top: 0;
      }
    }

    .grid-col-txt:nth-child(2n+2) .entry {
      float: right;
    }

    .grid-col-txt:first-child,
    .grid-col-txt:last-child {
      .entry {
        float: none;
        max-width: 50%;
        margin: 0 auto;
        text-align: center;
        padding: .5em 0;
        margin-bottom: 2em;
      }
      .entry__title {
        color: $c-gold;
        font-size: em(33);
      }
      p {
        font-size: em(20);
        line-height: 1.6;
        margin: 0;
      }
    }*/

  }

}
