/**
 * ==================== @ LIGHTBOX
 */

 [data-lb-src] {
   cursor: pointer;
 }

.lightbox {
  background-color: rgba(#fff, 0.9);
  height: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: opacity 0.1s ease;

  &::before {
    content: '🗙';
    cursor: pointer;
    font-size: 2em;
    position: absolute;
    right: 3%;
    top: 4%;
  }

  img {
    position: absolute;
    left: 50%;
    max-height: 90%;
    max-width: 90%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.is-open {
    height: 100%;
    opacity: 1;
  }
}
