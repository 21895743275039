/**
 * ==================== @ THEME VARIABLES
 */

// --- Breakpoints

$bp-s: 40em;    // 640px
$bp-m: 48em;    // 768px
$bp-l: 64em;    // 1024px
$bp-1280: 80em; // 1280px
$bp-xl: 100em;  // 1600px

// --- Paths
$font-path: '/assets/fonts/';
$img-path: '/assets/images/';

// --- Fonts
$icons-fontname: 'icons';
$default-font-size: 16px;
$font-stack: 'Nunito', Helvetica, Arial, sans-serif;
$font-txt: 'Lato', Helvetica, Arial, sans-serif;

$fw-light: 300;
$fw-regular: 400;
$fw-semibold: 600;
$fw-bold: 700;
$fw-black: 900;

// --- Colors
$c-white: #ffffff;
$c-black: #000100;
$c-dark: #323232;
$c-blue: #093e54;
$c-gold: #a15f30;
$c-grey: #989898;
$c-grey-lite: #f7f4f4;
$c-sand: #e49258;
$c-primary: $c-dark;


// --- Layout vars (margins / paddings)
$layout-padding: 1rem;
$grid-offset: 8.3333%;
$grid-offset-2: 16.666%;
$grid-outset: calc(8.3333% - 2rem);
$cols-padding: 1rem;
$cols-padding-m: 2rem;
