/**
 * ==================== @ TRADES
*/

.trades {
  overflow: hidden;
  padding-top: 4rem;

  .grid-layout {
    padding-bottom: 3rem;

    &.m--blue {
      h3, .caption, .pattern-env2, .svg-icon, .chapo {
        color: $c-blue;
      }
    }

    &.m--gold {
      h3, .caption, .pattern-env2, .svg-icon, .chapo {
        color: $c-gold;
      }
    }
  }

  .section-background {
    position: absolute;
    left: -1rem;
    right: -1rem;
    top: 3rem;
    bottom: 0;
    z-index: -10;

    .section-background__inner {
      background-color: #fff;
      height: 100%;
    }
  }

  .grid-col__pattern {
    display: none;
    order: 3;
    padding: 4rem;
    position: relative;
    z-index: 4;

    .pattern-duo {
      height: 200px;
      width: 236px;
      position: absolute;
      left: 50%;
      top: -100px;
      transform: translateX(-50%);
    }
  }

  .m--layout-2 {

    .grid-col-txt {
      order: 1;
    }

    .grid-col-pic {
      order: 2;
    }
  }

  // Texts
  .grid-col-txt {

    h3 {
      font-size: em(26, 16);
      line-height: 1.2;
      margin: .5em 0;
    }
  }

  // Images
  .picture__wrapper {
    display: inline-block;
    position: relative;

    .pattern-env2, .pattern-env1 {
      display: none;
    }
  }

  .picture__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;

    .svg-icon {
      color: #fff !important;
      height: auto;
      width: 200px;
      transform: translate(-50%,-50%);
    }
  }

  .grid-col-pic.m--img {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
  }

  &.m--founders {

    .caption  {
      line-height: 1.6;
      h3 {
        color: $c-gold;
        font-size: em(20);
      }
    }
  }
}


@media screen and (min-width:$bp-m) {

  .trades {

    .m--layout-2 {

      .grid-col-txt {
        order: 2;
      }

      .grid-col-pic {
        order: 1;
      }
    }
  }
}

@media screen and (min-width:$bp-l) {

  .trades {

    figure.m--no-size {
      position: absolute;
      top: 0;
    }

    .section-background {
      left: 1rem;
      right: 1rem;
      padding-left: $grid-offset;
      padding-right: $grid-offset;

      .section-background__inner {
        margin-left: 5rem;
        margin-right: 5rem;
      }
    }

    .grid-col-txt {

      h3 {
        font-size: em(48, 16);
        margin: .5em 0;
      }
    }

    // Images
    .grid-col-pic {
      padding-left: 0;
      text-align: right;
    }

    .picture__wrapper {
      height: 414px;
      width: 414px;
      overflow: hidden;
      border-radius: 50%;

      .pattern-env2, .pattern-env1 {
        display: block;
      }

      .svg-icon {
        display: inline-block;
        height: 414px;
        width: 414px;
      }

      + .picture-size {
        position: relative;
        top: -2.5em;
        display: inline-block;
      }

      figure {
        right: 5.2rem;
      }
    }

    figure {
      overflow: hidden;

      img {
        height: 414px;
        width: auto;
        max-width: none;
      }
    }

    .picture__logo {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;

      .svg-icon {
        color: #fff;
        height: auto;
        width: 200px;
        transform: translate(-50%,-50%);
      }
    }

    .m--layout-2 {

      .grid-col-pic {
        text-align: left;

        figure {
          left: 5.2rem;
          right: 0;
        }
      }
    }
  }
}

@media screen and (min-width:$bp-1280) {

  .trades.m--img-right {
    figure {
      padding-left: 2.5rem;
      .picture-size {
        left: 2.5rem;
      }
    }
  }

  .trades.m--img-left {
    figure {
      padding-right: 2.5rem;
    }
  }

}
