/**
 * ==================== @ SASS FUNCTIONS
 */

@function path($ressource_path, $base_path: $img-path) {
  @return ($base_path + $ressource_path);
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@function em($pixels, $context: $default-font-size) {
  @return #{strip-unit($pixels)/strip-unit($context)}em;
}

@function rem($pixels, $context: 16px) {
  @return #{strip-unit($pixels)/strip-unit($context)}rem;
}

@function lh($pixels, $context: $default-font-size) {
  @return strip-unit($pixels / $context);
}

