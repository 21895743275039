/**
 * ==================== @ Notre équipe
*/

.our-team {

	.gallery {
		background-color: $c-grey-lite;

		.grid-col-pic {
			margin-bottom: 2.5em;
			text-align: center;
      position: relative;

      .picture-size span {
        display: none;
      }
    }

    .member__photo {
      position: relative;
      display: inline-block;

      .svg-icon {
        font-size: 70px;
        position: absolute;
        top: 140px;
        right: -20px;
      }
    }

		.gallery__title {
			color: $c-gold;
			font-size: em(26);
			margin: 1em 0 0 0;
    }

		p {
			margin-top: em(5);
		}
	}

}

@media screen and (min-width:$bp-s) {

	.our-team {

		.gallery {

			.grid-col-pic {
				text-align: initial;
			}

		}

	}

}
