/**
 * ==================== @ BANNER
*/

.banner {
  color: #FFF;
  padding: 2em 0;
  position: relative;
  text-align: center;

  .svg-icon.material,
  .svg-icon.waves {
    width: 100px;
    height: 80px;
  }

  p {
    font-size: rem(22);
    margin: 0;
  }

  span {
    font-size: rem(18);
  }

  .bt-wrapper {
    margin-top: 1rem;
  }

}

@media screen and (min-width:$bp-l) {

  .banner {
    text-align: initial;
    padding-left: 8rem;

    .svg-icon.material,
    .svg-icon.waves {
      position: absolute;
      left: -80px;
      top: 50%;
      transform: translatey(-50%);
    }

    p {
      font-size: rem(33);
      margin: 0 0 0 1em;
      max-width: 580px;
    }

    span {
      font-size: rem(26);
    }

    .btn-wrapper {
      position: relative;
      top: 50%;
      transform: translatey(-50%);
    }

  }

}
