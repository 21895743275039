/**
 * ==================== @ BASE STYLES
 */

@font-face {
  font-family: 'Nunito';
  src: url(path('nunito-semibold.woff2', $font-path)) format('woff2'),
  url(path('nunito-semibold.woff', $font-path)) format('woff');
  font-weight: $fw-semibold;
  font-style: normal;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
	background-color: $c-grey-lite;
  color: $c-primary;
  font: em($default-font-size, 16) $font-stack;
	letter-spacing: .5px;
  //overflow-x: hidden;
  position: relative;

  &.is-overlayed {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

figure {
  margin: 0;
}

iframe { border: 0 none; }

img {
  height: auto;
  max-width: 100%;
}

input,
textarea,
select { border-radius: 0; }

.sv-g [class*="sv-u"] {
	font-family: $font-stack;
	letter-spacing: .1px;
}

figure {
 display: inline-block;
 position: relative;

 picture, img {
   display: block;
 }
}

.picture-size {
  background: rgba($c-white, 0.8);
  bottom: 0;
  color: $c-grey;
  font-family: $font-txt;
  font-style: italic;
  font-size: 12px;
  left: 0;
  padding: 0.4em 0.3em;
  position: absolute;
}

@media screen and (min-width: $bp-m) {
	body {
		padding-top: 120px;
	}
}
