/**
 * ==================== @ BUTTONS
 */

.btn-wrapper {
	font-size: 16px;
	padding: 1rem 0;

	&.m--center {
		text-align: center;
	}
}

.btn {
	display: inline-block;
	border-radius: em(20, 16);
	border: 2px solid $c-gold;
	color: $c-gold;
	font-size: em(20, 16);
	line-height: 1.4;
  padding: .5rem 1rem;
	transition: background-color .2s ease, color .2s ease;

	.svg-icon {
    color: inherit !important;
    font-size: em(24, 16);
		margin-right: .2em;
	}

	&.m--blue {
		border-color: $c-blue;
		color: $c-blue;
	}

	&.m--gold{
		background-color: $c-gold;
		color: #fff;
	}

	&.m--bg-white {
		background-color: #fff;
		border-color: #fff;
	}

	&.m--big {
		border-radius: em(26, 16);
		font-size: em(26, 16);
	}

	&.btn-link .svg-icon {
		font-size: em(16);
		margin-left: .5em;
	}

	&.btn-gie {
		color: $c-blue;
		padding: .25rem 1rem;
	}
}

@media screen and (min-width:$bp-l) {

	.btn {
		&:hover {
			background-color: $c-gold;
			color: #fff;
		}

		&.m--blue:hover {
			background-color: $c-blue;
    }

    &.m--gold:hover {
			background-color: transparent;
			color: $c-gold;
    }

    &.btn-gie:hover {
			color: #fff !important;
    }
	}
}
