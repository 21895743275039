/**
 * ==================== @ Nos Services
*/

.services {

  .grid-col-txt {
    padding-bottom: 2em;

		h3 {
			color: $c-gold;
			font-size: em(32);
			line-height: 1.2;
			max-width: 450px;
			margin-top: 1em;
		}
  }

  .grid-layout {
    &.m--blue {
      .services__svg,
      .entry__title {
        color: $c-blue;
      }
    }
    &.m--gold {
      .services__svg,
      .entry__title,
      .chapo {
        color: $c-gold;
      }
    }
  }

  .services__svg {
    width: 170px;
    height: 170px;

    .svg-icon {
      font-size: 170px;
    }
  }

}

@media screen and (min-width:$bp-l) {

  .services {
    margin-top: 4rem;

    .grid-col-txt {
      padding-bottom: 4em;
    }

    .services__heading p {
      font-size: em(20);
      max-width: 500px;
    }

  }

}
