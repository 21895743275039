/**
 * ==================== @ Really ?!
 */
 // Pattern colors
.pattern-sail,
.pattern-sail2 {
	color: $c-blue;
}
.pattern-env {
	color: $c-gold;
}

// Filet central
.timeline-streak {
  height: 2rem;
	position: relative;
	z-index: -1;

	.shape {
		background-color: rgba($c-sand,.5);
		width: 2px;
		font-size: 0;
		position: absolute;
		left: 2%;
	}

}

.produit-pic {
	border: none !important;
	img {
		height: auto !important;
	}

}

@media screen and (min-width:$bp-l) {

	.timeline-streak {
		height: 4rem;

		.shape {
			left: 50%;
    }

    + section {
      margin-top: 0 !important;
    }
	}
}
