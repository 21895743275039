/**
 * ==================== @ KAIRLIN USAGES
*/

.kairlin-use {
	padding: 4rem 0;

	.grid-layout {

		.slide {
			padding: 1.2rem;
		}

		.slide-caption {
      color: $c-blue;
      font-size: 14px;
      margin-top: 0.5em;
		}
	}
}

@media screen and (min-width:$bp-l) {

}
