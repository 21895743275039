/**
 * ==================== @ GENERIC / PAGES
 */

.page-header {

  .page-header__title {
    color: $c-blue;
    font-size: em(36);
    line-height: .9;

    strong {
      color: $c-gold;
      display: block;
    }

    &.m--overlap {
      position: relative;
      width: 160%;
      z-index: 2;
    }

    &.m--page {
      color: $c-gold;
      font-size: 3em;
      margin: 0;
      padding: 1rem 1.8rem;
    }
  }

  .m--bg-white {
    background-color: #fff;
    padding-top: 2rem;
  }

  .offset-sides .page-header__title {
    padding-left: 0;
    padding-right: 0;
  }

  .page-header__intro {
    margin: 2rem 0;
    font-size: em(20);
    color: $c-blue;
  }

  .page-header__caption {
    text-align: center;
    font-style: italic;
    color: $c-grey;
    font-size: em(18);
    margin-bottom: 1.5em;
    p {
      display: inline-block;
      max-width: 600px;
    }
  }

  .grid-col-txt {

    cite {
      color: $c-blue;
      font-size: em(24);
      font-style: normal;

      small {
        color: $c-blue;
        display: block;
        font-size: rem(13);
      }
    }

    cite.m--gold {
      color: $c-gold;
    }

    p {
      font-size: em(18);
    }
  }

  .caption {
    p {
    color: $c-blue;
    font-size: em(13);
    }
    span {
      color: $c-gold;
      font-style: italic;
    }
  }

  figure {
    max-height: 560px;
    overflow: hidden;

    .picture-size {
      bottom: auto;
      left: 0;
      right: auto;
      top: 0;
    }
  }

}

/**
 * ==================== @ SPECIFIC PAGES
 */

.sailing .page-header,
.environnement .page-header,
.kairlin .page-header {

  .page-header__visual, .page-header__title {
    margin: 0;
    position: relative;
    text-align: center;
  }

  .page-header__visual {
    margin-bottom: 2.5rem;
    max-width: 1000px;
    margin: 0 auto;
  }

  .page-header__title {
    margin-top: -3.2rem;

    .svg-icon {
      height: 160px;
      width: 386px;
      max-width: 100%;
    }
  }

  .grid-col-pic {
    text-align: center;
  }
}

.kairlin .page-header {

  .page-header__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-66%);
    .svg-icon {
      color: #fff;
      height: 90px;
      width: 256px;
    }
  }

  .page-header__title {
    font-size: em(48);
    margin: 0 auto;
    max-width: 800px;
    padding: 4rem 0;

    strong {
      display: inline;
    }
  }
  .page-header__visual > figure > picture > img {
    min-height: 200px;
    object-fit: cover;
  }
}

/**
 * ==================== @ NAV SECONDARY
 */

.nav-secondary {
  background-color: #fff;
  padding: 1em 0;
  text-align: center;

  li {
    display: inline-block;
    padding: 0 1em;

    a {
      display: block;
      color: $c-blue;
      padding: 1em 0;

      &:hover {
        color: $c-gold;
      }
    }
  }
}


/**
 * ==================== @ SECTIONS
 */

.section-title {
  color: $c-gold;
  font-size: em(32);
  line-height: .9;
  margin: 0 0 3rem 0;

  strong {
    color: $c-blue;
  }

}

.section-title__wrapper {
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;

  .svg-icon {
    height: auto;
    width: 120px;
    position: relative;
    margin-left: -3rem;
    margin-top: -1rem;
  }

  .section-title {
    margin-left: 0;
  }

  &.m--right {
    .section-title {
      margin-left: auto;
      margin-right: 6rem;

      strong {
        margin-right: .5em;
      }
    }
  }
}

@media screen and (min-width:$bp-l) {

  .page-header {

    .grid-col-txt {
      position:relative;
      padding-top: 3.6rem;
      z-index: 1;
    }

    .page-header__title {
      font-size: em(79);
      margin-top: 4rem;
      margin-bottom: 3rem;

      &.m--overlap {
        width: 180%;
      }
      &.m--page {
        font-size: em(64);
      }
    }

    .page-header__caption {
      font-size: em(26);
    }

    .grid-col-txt {

      cite {
        font-size: em(33);
        line-height: 1.3;
        max-width: 380px;
        display: inline-block;
      }

      p {
        font-size: em(20);
        max-width: 380px;
      }
    }

    .caption {
      p {
        text-align: right;
        margin: 0;
      }
    }

    .grid-col-pic {
      padding-left: 0;
      img {
        width: 100%;
      }
    }

  }

  /* Nav secondary */

  .nav-secondary {
    font-size: em(20);
  }

  /* SECTIONS */

  .section-title {
    font-size: em(64);
    line-height: rem(76);
    margin: 0 0 3rem 0;

    strong {
      display: block;
      margin-left: .5em;
    }

    &.m--left  {
      margin-left: -2rem;
      strong {
        margin-left: 0;
      }
    }
  }

  .section-title__wrapper {
    margin-left: 4rem;
    margin-right: 4rem;

    .svg-icon {
      margin-left: -11rem;
      margin-top: 0;
      position: absolute;
      width: 250px;
    }

    .section-title {
      font-size: em(90);
      margin-left: 6rem;
    }

    &.m--right {
      text-align: right;
      position: relative;
      right: 3%;

      .section-title {
        margin-left: auto;
        margin-right: 12rem;

        strong {
          margin-right: .5em;
        }
      }
    }
  }
  .kairlin .page-header .page-header__title {
    font-size: em(64);
  }
}

@media screen and (min-width:$bp-1280) {

  .section-title__wrapper.m--right {

    .section-title {
      margin-right: 6rem;
    }

    .svg-icon {
      margin-left: -4rem;
    }

  }

}
