/**
 * ==================== @ GENERIC FORMS
*/

.sv-form {
	
	fieldset {
		border: none;
		padding: 0;
	}
	
	legend {
		font-size: em(26);
	}
	
	.grid-form {
	}		
}

.form-field {
	padding: .5em 0;
	margin-bottom: .5em;
}

.form-label {
	display: block;
	font-family: $font-txt;
	font-size: em(13);
	padding: .2em 0;
	
	&.required:after {
		content: '*';
		margin-left: .5em;
		color: $c-gold;
	}
}

.form-field__input, .form-field__select, .form-field__text {
	border: 2px solid rgba($c-gold,.5);
	border-radius: 4px;
	color: $c-blue;
	font-family: $font-txt;
	font-size: em(16);
	padding: .5em;
	width: 100%;
}

.form-field__text {
	min-height: 6em;	
}

.m--fieldleft {
	padding-left: 0;
}

.m--fieldright {
	padding-right: 0;
}

.form-field__submit {
	background-color: $c-gold;
	border-radius: 1em;
	border: none;
	color: #fff;
	font-size: em(20);
	padding: .5em 1.2em;
	
	.svg-icon {
		font-size: em(20);
		margin-left: .5em;
		margin-top: -5px;
		vertical-align: middle;
	}
}

@media screen and (min-width:$bp-l) {
	
	fieldset {
		padding-top: 0.35em;
		padding-right: 0.625em;
		padding-bottom: 0.75em;
		padding-left: 0.625em;
	}
	
	.m--fieldleft {
		padding-left: 1rem;
	}
	
	.m--fieldright {
		padding-right: 1rem;
	}
}
