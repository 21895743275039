/**
 * ==================== @ CONTACT FORM
*/

.contact-form {
	background-color: #fff;
	padding: 2rem 0;

	.section-title {
		color: $c-blue;
		font-size: em(52);
	}

	.grid-col-txt {
		padding-left: 0;
		padding-bottom: 4rem;

		h4 {
			font-size: em(20);
			line-height: 1.6;
			margin-top: 0;
		}

		strong {
			color: $c-gold;
		}

		li {
			margin-bottom: 1rem;

			.svg-icon {
				color: $c-gold;
				font-size: em(32);
				margin-right: .5em;
			}

			a {
				text-decoration: underline;
			}
		}
	}
}

@media screen and (min-width:$bp-m) {

	.contact-form {

		.grid-col-txt {
			padding-right: 10rem;
		}
	}

}
