/**
 * ==================== @ ICONS
 */

.svg-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling
  width: 1em;

  &.kairlin {
    width: 2.427734375em;
  }

  &.wave {
    width: 1.375em;
  }

  &.line {
    width: 1.625em;
  }

  &.kairos-sailing {
    color: $c-blue;
  }

  &.kairos-env {
    color: $c-gold;
  }
}
