/**
 * ==================== @ page header scroll anchor
*/

.headscroll {
  display: none;

  .svg-icon {
    display: block;
    height: 166px;
    width: 166px;
    margin: 0 auto;
  }

}

@media screen and (min-width:$bp-l) {

  .headscroll {
		display: block;
    height: 0;
    position: relative;
    bottom: 90px;

    &.m--left {
      bottom: 180px;
      left: 30px;
      max-width: 166px;
    }

    &.m--left .svg-icon {
      margin: 0;
    }
  }

	.kairlin .headscroll {
		bottom: 130px;
	}

}

@media screen and (min-width:$bp-xl) {

  .headscroll {
   // margin-top: -30px;
    .svg-icon, {
    //  min-height: 166px;
    }
  }

}
