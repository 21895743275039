.meet-us {
	margin-top: 8em;
	background-color: #fff;
  padding-bottom: 4rem;

	.grid-layout {
		background-color: #fff;
		padding-top: 2rem;
		position: relative;

		.entry {
			color: #fff;
			font-size: em(26);
		}
	}

	.grid-col-left {
		background-color: $c-blue;
		padding: 1rem 1rem 6rem 1rem;
	}
  .shape-solo + .grid-col-left {
    padding: 6rem 1rem 1rem 1rem;
  }
	.grid-col-right {
		background-color: $c-gold;
		padding: 6rem 1rem 1rem 1rem;
	}

	.tease {
		font-size: em(16);
		font-family: $font-txt;
		font-style: italic;
		color: $c-grey;
	}

	.shape-duo {
		background: transparent;
		position: relative;

		figure {
			position: absolute;
			left: 50%;
			bottom: -18rem;
			transform: translateX(-50%);
		}
	}

	.shape {
		display: none;
	}
}

.meet-us.m--page {
  margin-top: 0;
  padding-top: 4rem;

  p, span {
    display: block;
    margin: 0;
  }
  .shape-solo {
    position: absolute;
    top: -2rem;
  }
}

@media screen and (min-width:$bp-l) {

  .meet-us {
    padding-bottom: 4rem;

    .grid-layout {
      padding: 2rem;

      .entry {
        color: #fff;
        font-size: em(26);
      }
    }

    .grid-col-left {
      padding: 6rem 9rem 6rem 5rem;
    }

    .grid-col-right {
      padding: 6rem 2rem 6rem 9rem;
    }

    .tease {
      font-size: em(20);
    }

    .shape-duo {
      background: transparent;
      position: relative;

      figure {
        position: absolute;
        bottom: -16.5rem;
      }
    }

    .shape {
      display: block;
      position: absolute;
      height: 414px;
      width: 414px;

      .svg-icon {
        height: 414px;
        width: 414px;
      }

      &.pattern1 {
        color: $c-blue;
        left: $layout-padding;
        top: -11%;
      }

      &.pattern2 {
        color: $c-gold;
        right: $layout-padding;
        bottom: 20%;
      }
    }
  }

  .meet-us.m--page {

    .shape.pattern1 {
      top: -15%;
    }

    .shape.pattern2 {
      bottom: 5%;
    }

    .shape-solo {
      position: static;
      figure {
        position: absolute;
        right: 10%;
        top: 50%;
        transform: translatey(-50%);
      }
    }
  }

}
