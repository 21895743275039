
.entry, p {
	font-size: 1em;
	line-height: 1.6;

	strong {
		color: $c-gold;
	}

	p {
		font-size: inherit;
  }

  a:not(.btn) {
    text-decoration: underline;
  }
}

.m--blue {
	color: $c-blue;
}
