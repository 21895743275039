/**
 * ==================== @ HOME
 */

.home {

  .page-header {

    .grid-layout {
      width: 100vw;
    }

    .page-header__title {
      font-size: em(36);
    }

    .grid-col-txt cite {
      font-size: 2rem;
    }

    .grid-col-pic {
      margin-left: -8px;

      figure {
        max-height: 620px;
      }
    }
  }

  /**
    * ==================== @ SECTIONS
    */

  .edito {
    padding-bottom: 4rem;

    .section-title {
      position: relative;
      z-index: 4;
    }

    p {
      font-size: em(14);
    }
  }

  .low-consumption {
    background-color: $c-blue;
    color: #fff;
    padding-bottom: 4em;
    position: relative;

    .section-title {
      color: $c-sand;

      strong {
        color: #fff;
      }
    }

    .section-sub-subtitle {
      color: $c-sand;
      font-size: em(20);
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: .5em;
    }

    .grid-layout {
      margin-top: 2em;

      div {
        padding-right: 1rem;
      }

      .bc-picto {
        position: relative;
        text-align: center;
        height: 172px;
        width: 172px;
      }

      h3 {
        margin-bottom: .2em;
      }

      .svg-icon {
        position: absolute;
        &.picto {
          color: $c-sand;
          font-size: em(72);
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%)	;
        }
      }

      .half-circle {
        font-size: 0;
        height: 134px;
        width: 134px;
      }

      .circle-left {
        color: $c-sand;
        top: 0;
        left: 0;
      }

      .circle-right {
        color: #fff;
        right: 0;
        bottom: 0;
      }
    }

    h3 {
      color: $c-sand;
      font-size: em(42);
    }

    p {
      font-family: $font-txt;
      font-size: em(16,16);
    }
  }

  .latestnews {
    background-color: $c-grey-lite;
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
}

@media screen and (min-width:$bp-l) {

  .home {

    .page-header {

      .grid-layout {
        width: auto;
      }

      .page-header__title {
        font-size: em(79);
      }

    }

    /**
     * ==================== @ SECTIONS
     */

    .edito {
      .section-title {
        width: 150%;
      }

      p {
        font-size: em(16) !important;
      }
    }

    .low-consumption {
      padding-top: 4rem;

      .grid-layout {
        div {
          padding-right: 4rem;
        }
      }
    }

    .latestnews {
      padding-bottom: 2rem;
    }
  }
}
