/**
 * ==================== @ BLOG
 */

.article-teaser {
  display: block;
  padding: 0 1rem 4rem 1rem;
  position: relative;
  text-align: left;

  .icon-holder  {
    position: absolute;
    z-index: 2;
    width: 4.5rem;
    height: 4.5rem;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: rem(48);
      height: rem(48);
      border-radius: 100%;
      z-index: -1;
      background-color: $c-grey-lite;
    }
    .svg-icon {
      vertical-align: initial;
      height: 100%;
    }
  }

  .icon-holder.m--pattern-single,
  .icon-holder.m--pattern-single-gold {
    font-size: 4.5rem;
    left: -3rem;
    top: -2.5rem;
    &:before {
      top: rem(12);
      left: rem(28);
    }
  }

  .icon-holder.m--pattern-duo {
    font-size: rem(67);
    left: -1.5rem;
    top: -2.5rem;
    &:before {
      left: rem(-5);
      top: initial;
      bottom: rem(3);
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: rem(48);
      height: rem(48);
      border-radius: 100%;
      z-index: -1;
      background-color: $c-grey-lite;
      top: rem(3);
      right: rem(1);
    }
  }

  .svg-icon.arrow-right {
    font-size: 3rem;
    color: $c-gold;
    border: 2px solid $c-gold;
    border-radius: 100%;
    padding: .5rem;
    position: absolute;
    bottom: -1rem;
    right: 2rem;
  }

  a {
    display: block;
    background-color: #FFF;
    padding: 1rem 2rem;
    height: 100%;
    position: relative;
    &:hover h3 {
      text-decoration: underline;
    }
    &:hover .svg-icon.arrow-right {
      color: #FFF;
      background-color: $c-gold;
    }
  }

  h3 {
    color: $c-blue;
    line-height: 1.3;
    margin-top: 0;
  }

  .infos {
    color: $c-dark;
    font-family: $font-txt;
    margin: rem(5) 0 1rem;
    padding: 0;
    width: 100%;
  }

  .theme {
    font-weight: bold;
  }

  .date {
    font-style: italic;
    font-size: 0.8em;
  }

}

.article {
  .page-header {
    .date {
      color: $c-grey;
      font-style: italic;
      font-size: 0.8em;
      margin-bottom: 1rem;
      text-align: right;
    }
  }

  .page-header__title.m--page {
    margin-top: .5em;
  }

  .text-wide {
    .l-wrapper {
      max-width: em(850);
    }
  }

  h1:not(.page-header__title),
  h2 {
    margin-top: -10px;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: em(20);
    color: $c-blue;
  }

  p {
    margin-top: 0;
  }

  ul {
    line-height: 1.5em;
  }

  .produit-pic {
    top: 4px;
  }
}

.separator {
  height: 4em;
}

@media screen and (min-width:$bp-1280) {

  .article-teaser {
    padding: 0 2.5rem 4rem 0;
  }

  .article {
    .page-header {
      .date {
        padding: 0 3rem;
      }
    }
  }

}
