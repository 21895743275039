/**
 * ==================== @ PAGINATION
*/

.pagination__list li {
  display: inline-block;
  vertical-align: middle;
  a, span {
    color: $c-gold;
    &:hover {
      color: $c-blue;
    }
  }
}

.pagination__number {
  display: inline-block;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  padding: .5rem;
  width: 3rem;
  height: rem(42);
  line-height: 1.5rem;
  .svg-icon {
    display: none;
  }
  &[aria-current="page"] {
    color: $c-blue;
    .svg-icon {
      display: block;
      font-size: 3rem;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.pagination__prev,
.pagination__next {
  font-size: 2rem;
}

.pagination__prev {
  margin-right: .5rem;
}

.pagination__next {
  margin-left: .5rem;
}

.pagination__prev .svg-icon {
  transform: scaleX(-1);
}
