/**
 * ==================== @ HEADER
 */

.header {
	height: auto;
	padding-top: 1.5em;
}

.header-logo {
	float: left;
	font-size: .8em;
  margin-right: 2em;

	&.m--kairos {

		img {
			display: block;
			width: 130px;
		}

		span {
			color: $c-blue;
			display: block;
			font-size: em(10,16);
			margin-top: -8px;
			text-align: center;
		}
	}
}

.header-eco-label {
  display: none;
	float: left;
	font-size: .9em;
	margin-top: 1em;

	.svg-icon {
    margin-left: 0.2em;
		position: relative;
		top: -3px;
	}

	span {
		color: $c-gold;
	}

	em {
		color: $c-grey;
		display: block;
		font-size: em(13,16);
		font-family: $font-txt;
		font-style: italic;
	}
}

/**
 * ==================== @ NAV
 */

.header-nav {
  float: right;
  padding: 0 .5em;
  margin-top: .5em;

  &.is-open {
    .header-menu.m--full {
      display: block;
    }
    .header-nav__menu-toggler {
      color: $c-white;
    }
  }
}

.header-menu {
  color: $c-blue;
  display: none;
  vertical-align: middle;

	li {
		display: inline-block;

		a {
      display: block;
			font-size: 20px;
    }

    a + .svg-icon {
      display: none;
    }
  }

  &.m--mini {

    li {
      margin-right: 1.6em;
    }

  }

  &.m--full {
    background-color: $c-blue;
    color: $c-white;
    padding: 5.5em 2em 3em;
    position: absolute;
    min-height: 100vh;
    right: 0;
    top: -1.5em;
    width: 100%;
    z-index: 10;

    li {
      display: block;
      margin: 0 1em;
      min-width: 150px;

      &.group {
        border-top: 1px solid $c-gold;
        margin: 1em 0 0;
        padding: 1.5em 1em 0.5em;
      }

      &.social {
        a {
          padding: 0.2em 0;
        }

        + .group {
          margin-top: 1.5em;
        }
      }

      a {
        padding: 0.5em 0;

        .badge {
          display: inline-block;
          background-color: $c-white;
          border-radius: 50%;
          color: $c-gold;
          font-size: 16px;
          height: 28px;
          line-height: 28px;
          margin-right: 0.5em;
          width: 28px;
          text-align: center;

          .linkedin {
            vertical-align: -.14em;
          }
        }

        &:hover .badge {
          background-color: $c-gold;
          color: #fff;
        }
      }

      a, > .svg-icon {
        font-size: 18px;
      }
    }
  }
}

.header-nav__menu-toggler {
  color: $c-blue;
	display: inline-block;
	position: relative;
	width: 36px;
  height: 30px;
  vertical-align: middle;
  z-index: 11;

	span,
	&:before,
	&:after {
		display: block;
		width: 36px;
		height: 4px;
		position: absolute;
		background-color: currentColor;
    border-radius: 4px;
    // transition: transform 0.5s ease;
	}
	&:before,
	&:after {
		content: "";
	}
	&:before {
		top: 0;
    left: 0;
    transform-origin: top left;
	}
	&:after {
		bottom: 0;
		left: 0;
    transform-origin: bottom left;
	}
	span {
		left: 0;
    top: 13px;
    font-size: 0;
    text-indent: -999em;
  }

  .is-open & {
    &::before {
      left: 5px;
      transform: rotateZ(45deg);
      width: 38.5px;
    }
    span {
      opacity: 0;
    }
    &::after {
      left: 5px;
      transform: rotateZ(-45deg);
      width: 38.5px;
    }
  }
}

@media screen and (min-width:440px) {
  .header-eco-label {
    display: inline-block;
  }
}

@media screen and (min-width:$bp-m) {
	.header {
		background-color: $c-grey-lite;
		height: 120px;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
  }
}

@media screen and (min-width:$bp-l) and (max-width:1200px){
  .header-eco-label {
    display: none;
  }
}

@media screen and (min-width:$bp-l) {

	.header-logo {
		font-size: 1em;

		&.m--kairos {
			img {
				width: 176px;
			}
		}
	}

	.header-eco-label {
		font-size: 1em;
  }

  .header-nav {
    margin-top: 1em;
  }

	.header-menu {

    a:hover {
      color: $c-gold;
    }

    &.m--mini {
      display: inline-block;

      .active {
        color: $c-gold;

        a + .svg-icon {
          display: block;
          margin: 0 auto -1em;
        }
      }
    }

    &.m--full {
      min-height: auto;
      width: auto;
      right: -1.5em;

      li {
        &:nth-child(-n+3) {
          display: none;
        }
      }
    }
	}
}
