@charset "UTF-8";
/**
 * Use BEM : https://en.bem.info/
 */
/**
 * ==================== @ THEME VARIABLES
 */
/**
 * ==================== @ SASS FUNCTIONS
 */
/**
 * ==================== @ SASS MIXINS
 */
/**
 * ==================== @ SASS PLACEHOLDERS
 */
.l-clearfix::before, .l-clearfix::after {
  content: " ";
  display: table; }

.l-clearfix::after {
  clear: both; }

/**
 * ==================== @ BASE STYLES
 */
@font-face {
  font-family: 'Nunito';
  src: url("/assets/fonts/nunito-semibold.woff2") format("woff2"), url("/assets/fonts/nunito-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  background-color: #f7f4f4;
  color: #323232;
  font: 1em "Nunito", Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  position: relative; }
  body.is-overlayed {
    overflow: hidden; }

a {
  color: inherit;
  text-decoration: none; }

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0; }

figure {
  margin: 0; }

iframe {
  border: 0 none; }

img {
  height: auto;
  max-width: 100%; }

input,
textarea,
select {
  border-radius: 0; }

.sv-g [class*="sv-u"] {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  letter-spacing: .1px; }

figure {
  display: inline-block;
  position: relative; }
  figure picture, figure img {
    display: block; }

.picture-size {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  color: #989898;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 12px;
  left: 0;
  padding: 0.4em 0.3em;
  position: absolute; }

@media screen and (min-width: 48em) {
  body {
    padding-top: 120px; } }

/**
 * ==================== @ ICONS
 */
.svg-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em;
  width: 1em; }
  .svg-icon.kairlin {
    width: 2.427734375em; }
  .svg-icon.wave {
    width: 1.375em; }
  .svg-icon.line {
    width: 1.625em; }
  .svg-icon.kairos-sailing {
    color: #093e54; }
  .svg-icon.kairos-env {
    color: #a15f30; }

/**
 * ==================== @ GENERIC FLOW TOOLS
 */
.l-wrapper {
  letter-spacing: .1px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  max-width: 80em; }

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0; }

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0; }

.l-center {
  text-align: center; }

.l-right {
  text-align: right; }

/* --- EMBED --- */
.l-embed {
  position: relative;
  padding-bottom: 56.25%;
  /* ratio 16.9 */
  height: 0; }

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.page {
  min-height: 600px;
  overflow: hidden; }

.entry.m--big p {
  font-size: 1.25em;
  margin-top: 0.625em; }

.entry.m--blue p {
  color: #093e54; }

.entry__title {
  font-size: 2.0625rem;
  margin: 0; }

.bg-blue {
  background-color: #093e54; }

.bg-gold {
  background-color: #a15f30; }

.bg-white {
  position: relative; }
  .bg-white:before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 2rem);
    position: absolute;
    top: 2rem;
    right: 0;
    background-color: #FFF; }

/**
 * ==================== @ STATES
 */
.is-semantic {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  display: block;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.entry, p {
  font-size: 1em;
  line-height: 1.6; }
  .entry strong, p strong {
    color: #a15f30; }
  .entry p, p p {
    font-size: inherit; }
  .entry a:not(.btn), p a:not(.btn) {
    text-decoration: underline; }

.m--blue {
  color: #093e54; }

/**
 * ==================== @ HEADER
 */
.header {
  height: auto;
  padding-top: 1.5em; }

.header-logo {
  float: left;
  font-size: .8em;
  margin-right: 2em; }
  .header-logo.m--kairos img {
    display: block;
    width: 130px; }
  .header-logo.m--kairos span {
    color: #093e54;
    display: block;
    font-size: 0.625em;
    margin-top: -8px;
    text-align: center; }

.header-eco-label {
  display: none;
  float: left;
  font-size: .9em;
  margin-top: 1em; }
  .header-eco-label .svg-icon {
    margin-left: 0.2em;
    position: relative;
    top: -3px; }
  .header-eco-label span {
    color: #a15f30; }
  .header-eco-label em {
    color: #989898;
    display: block;
    font-size: 0.8125em;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-style: italic; }

/**
 * ==================== @ NAV
 */
.header-nav {
  float: right;
  padding: 0 .5em;
  margin-top: .5em; }
  .header-nav.is-open .header-menu.m--full {
    display: block; }
  .header-nav.is-open .header-nav__menu-toggler {
    color: #ffffff; }

.header-menu {
  color: #093e54;
  display: none;
  vertical-align: middle; }
  .header-menu li {
    display: inline-block; }
    .header-menu li a {
      display: block;
      font-size: 20px; }
    .header-menu li a + .svg-icon {
      display: none; }
  .header-menu.m--mini li {
    margin-right: 1.6em; }
  .header-menu.m--full {
    background-color: #093e54;
    color: #ffffff;
    padding: 5.5em 2em 3em;
    position: absolute;
    min-height: 100vh;
    right: 0;
    top: -1.5em;
    width: 100%;
    z-index: 10; }
    .header-menu.m--full li {
      display: block;
      margin: 0 1em;
      min-width: 150px; }
      .header-menu.m--full li.group {
        border-top: 1px solid #a15f30;
        margin: 1em 0 0;
        padding: 1.5em 1em 0.5em; }
      .header-menu.m--full li.social a {
        padding: 0.2em 0; }
      .header-menu.m--full li.social + .group {
        margin-top: 1.5em; }
      .header-menu.m--full li a {
        padding: 0.5em 0; }
        .header-menu.m--full li a .badge {
          display: inline-block;
          background-color: #ffffff;
          border-radius: 50%;
          color: #a15f30;
          font-size: 16px;
          height: 28px;
          line-height: 28px;
          margin-right: 0.5em;
          width: 28px;
          text-align: center; }
          .header-menu.m--full li a .badge .linkedin {
            vertical-align: -.14em; }
        .header-menu.m--full li a:hover .badge {
          background-color: #a15f30;
          color: #fff; }
      .header-menu.m--full li a, .header-menu.m--full li > .svg-icon {
        font-size: 18px; }

.header-nav__menu-toggler {
  color: #093e54;
  display: inline-block;
  position: relative;
  width: 36px;
  height: 30px;
  vertical-align: middle;
  z-index: 11; }
  .header-nav__menu-toggler span, .header-nav__menu-toggler:before, .header-nav__menu-toggler:after {
    display: block;
    width: 36px;
    height: 4px;
    position: absolute;
    background-color: currentColor;
    border-radius: 4px; }
  .header-nav__menu-toggler:before, .header-nav__menu-toggler:after {
    content: ""; }
  .header-nav__menu-toggler:before {
    top: 0;
    left: 0;
    transform-origin: top left; }
  .header-nav__menu-toggler:after {
    bottom: 0;
    left: 0;
    transform-origin: bottom left; }
  .header-nav__menu-toggler span {
    left: 0;
    top: 13px;
    font-size: 0;
    text-indent: -999em; }
  .is-open .header-nav__menu-toggler::before {
    left: 5px;
    transform: rotateZ(45deg);
    width: 38.5px; }
  .is-open .header-nav__menu-toggler span {
    opacity: 0; }
  .is-open .header-nav__menu-toggler::after {
    left: 5px;
    transform: rotateZ(-45deg);
    width: 38.5px; }

@media screen and (min-width: 440px) {
  .header-eco-label {
    display: inline-block; } }

@media screen and (min-width: 48em) {
  .header {
    background-color: #f7f4f4;
    height: 120px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10; } }

@media screen and (min-width: 64em) and (max-width: 1200px) {
  .header-eco-label {
    display: none; } }

@media screen and (min-width: 64em) {
  .header-logo {
    font-size: 1em; }
    .header-logo.m--kairos img {
      width: 176px; }
  .header-eco-label {
    font-size: 1em; }
  .header-nav {
    margin-top: 1em; }
  .header-menu a:hover {
    color: #a15f30; }
  .header-menu.m--mini {
    display: inline-block; }
    .header-menu.m--mini .active {
      color: #a15f30; }
      .header-menu.m--mini .active a + .svg-icon {
        display: block;
        margin: 0 auto -1em; }
  .header-menu.m--full {
    min-height: auto;
    width: auto;
    right: -1.5em; }
    .header-menu.m--full li:nth-child(-n+3) {
      display: none; } }

/**
 * ==================== @ page header scroll anchor
*/
.headscroll {
  display: none; }
  .headscroll .svg-icon {
    display: block;
    height: 166px;
    width: 166px;
    margin: 0 auto; }

@media screen and (min-width: 64em) {
  .headscroll {
    display: block;
    height: 0;
    position: relative;
    bottom: 90px; }
    .headscroll.m--left {
      bottom: 180px;
      left: 30px;
      max-width: 166px; }
    .headscroll.m--left .svg-icon {
      margin: 0; }
  .kairlin .headscroll {
    bottom: 130px; } }

.footer {
  background-color: #093e54;
  padding-bottom: 4em; }

.footer-baseline {
  background-color: #f7f4f4;
  padding-left: 1rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  position: relative; }
  .footer-baseline .website-eco-mention {
    color: #989898;
    padding-left: 1rem;
    position: absolute;
    left: 0;
    top: 1rem; }
  .footer-baseline .grid-col-txt {
    font-size: 0.875em;
    line-height: 1.6;
    padding: .5rem 0; }
    .footer-baseline .grid-col-txt span {
      color: #989898;
      display: block;
      font-size: 0.8125em;
      font-family: "Lato", Helvetica, Arial, sans-serif;
      font-style: italic; }
    .footer-baseline .grid-col-txt strong {
      color: #a15f30; }
    .footer-baseline .grid-col-txt .svg-icon {
      color: #a15f30;
      font-size: 3em;
      float: left;
      margin-right: 1rem; }

.footer-nav {
  color: #fff;
  padding-top: 2em; }
  .footer-nav h3 {
    color: #e49258;
    display: none;
    font-size: 1.25em;
    margin: 2em 0 1.3em; }

.footer-menu {
  display: none; }
  .footer-menu li {
    display: block;
    margin-bottom: .5em; }
    .footer-menu li a {
      font-size: 1em;
      color: #fff; }
      .footer-menu li a:hover {
        text-decoration: underline; }

.footer-nav__rs {
  padding-top: 2rem;
  padding-left: 1rem;
  text-align: center; }
  .footer-nav__rs .kairos-rs {
    color: rgba(255, 255, 255, 0.2);
    font-size: 4.5em; }
  .footer-nav__rs p {
    font-size: 0.8125em;
    font-family: "Lato", Helvetica, Arial, sans-serif; }
  .footer-nav__rs > a:hover {
    text-decoration: underline; }
  .footer-nav__rs li {
    display: inline-block; }
    .footer-nav__rs li + li {
      margin-left: 0.4em; }
    .footer-nav__rs li a {
      background-color: #fff;
      border-radius: 50%;
      color: #a15f30;
      display: block;
      line-height: 28px;
      text-align: center;
      height: 28px;
      width: 28px; }
      .footer-nav__rs li a:hover {
        background-color: #a15f30;
        color: #fff; }
    .footer-nav__rs li .svg-icon {
      font-size: 1em; }

@media screen and (min-width: 64em) {
  .footer-baseline {
    padding-left: 20%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative; }
    .footer-baseline .website-eco-mention {
      padding-left: 2rem;
      position: absolute;
      left: 0;
      top: auto; }
      .footer-baseline .website-eco-mention span {
        display: block; }
    .footer-baseline .grid-col-txt {
      font-size: 1em;
      padding: 0; }
      .footer-baseline .grid-col-txt .svg-icon {
        margin-right: 1.2rem; }
  .footer-nav__rs {
    text-align: left; }
  .footer-nav h3 {
    display: block; }
  .footer-menu {
    display: block; } }

/**
 * ==================== @ BUTTONS
 */
.btn-wrapper {
  font-size: 16px;
  padding: 1rem 0; }
  .btn-wrapper.m--center {
    text-align: center; }

.btn {
  display: inline-block;
  border-radius: 1.25em;
  border: 2px solid #a15f30;
  color: #a15f30;
  font-size: 1.25em;
  line-height: 1.4;
  padding: .5rem 1rem;
  transition: background-color .2s ease, color .2s ease; }
  .btn .svg-icon {
    color: inherit !important;
    font-size: 1.5em;
    margin-right: .2em; }
  .btn.m--blue {
    border-color: #093e54;
    color: #093e54; }
  .btn.m--gold {
    background-color: #a15f30;
    color: #fff; }
  .btn.m--bg-white {
    background-color: #fff;
    border-color: #fff; }
  .btn.m--big {
    border-radius: 1.625em;
    font-size: 1.625em; }
  .btn.btn-link .svg-icon {
    font-size: 1em;
    margin-left: .5em; }
  .btn.btn-gie {
    color: #093e54;
    padding: .25rem 1rem; }

@media screen and (min-width: 64em) {
  .btn:hover {
    background-color: #a15f30;
    color: #fff; }
  .btn.m--blue:hover {
    background-color: #093e54; }
  .btn.m--gold:hover {
    background-color: transparent;
    color: #a15f30; }
  .btn.btn-gie:hover {
    color: #fff !important; } }

.grid-layout .grid-col-txt {
  padding-left: 1rem;
  padding-right: 1rem; }
  .grid-layout .grid-col-txt .chapo {
    font-size: 26px;
    line-height: 1.4; }

.offset-left {
  margin-left: 0; }

@media screen and (min-width: 48em) {
  .grid-layout {
    margin: 0 auto; }
    .grid-layout .grid-col-txt {
      padding-left: 2rem;
      padding-right: 2rem; }
    .grid-layout .grid-col-pic {
      padding-left: 1em; }
    .our-partners .grid-layout {
      max-width: 640px;
      text-align: center; }
  .offset-left {
    margin-left: 8.3333%; }
  .offset-sides {
    margin-left: 8.3333%;
    margin-right: 8.3333%; }
  .outset-sides {
    margin-left: calc(8.3333% - 2rem);
    margin-right: calc(8.3333% - 2rem); } }

/**
 * ==================== @ GENERIC / PAGES
 */
.page-header .page-header__title {
  color: #093e54;
  font-size: 2.25em;
  line-height: .9; }
  .page-header .page-header__title strong {
    color: #a15f30;
    display: block; }
  .page-header .page-header__title.m--overlap {
    position: relative;
    width: 160%;
    z-index: 2; }
  .page-header .page-header__title.m--page {
    color: #a15f30;
    font-size: 3em;
    margin: 0;
    padding: 1rem 1.8rem; }

.page-header .m--bg-white {
  background-color: #fff;
  padding-top: 2rem; }

.page-header .offset-sides .page-header__title {
  padding-left: 0;
  padding-right: 0; }

.page-header .page-header__intro {
  margin: 2rem 0;
  font-size: 1.25em;
  color: #093e54; }

.page-header .page-header__caption {
  text-align: center;
  font-style: italic;
  color: #989898;
  font-size: 1.125em;
  margin-bottom: 1.5em; }
  .page-header .page-header__caption p {
    display: inline-block;
    max-width: 600px; }

.page-header .grid-col-txt cite {
  color: #093e54;
  font-size: 1.5em;
  font-style: normal; }
  .page-header .grid-col-txt cite small {
    color: #093e54;
    display: block;
    font-size: 0.8125rem; }

.page-header .grid-col-txt cite.m--gold {
  color: #a15f30; }

.page-header .grid-col-txt p {
  font-size: 1.125em; }

.page-header .caption p {
  color: #093e54;
  font-size: 0.8125em; }

.page-header .caption span {
  color: #a15f30;
  font-style: italic; }

.page-header figure {
  max-height: 560px;
  overflow: hidden; }
  .page-header figure .picture-size {
    bottom: auto;
    left: 0;
    right: auto;
    top: 0; }

/**
 * ==================== @ SPECIFIC PAGES
 */
.sailing .page-header .page-header__visual, .sailing .page-header .page-header__title,
.environnement .page-header .page-header__visual,
.environnement .page-header .page-header__title,
.kairlin .page-header .page-header__visual,
.kairlin .page-header .page-header__title {
  margin: 0;
  position: relative;
  text-align: center; }

.sailing .page-header .page-header__visual,
.environnement .page-header .page-header__visual,
.kairlin .page-header .page-header__visual {
  margin-bottom: 2.5rem;
  max-width: 1000px;
  margin: 0 auto; }

.sailing .page-header .page-header__title,
.environnement .page-header .page-header__title,
.kairlin .page-header .page-header__title {
  margin-top: -3.2rem; }
  .sailing .page-header .page-header__title .svg-icon,
  .environnement .page-header .page-header__title .svg-icon,
  .kairlin .page-header .page-header__title .svg-icon {
    height: 160px;
    width: 386px;
    max-width: 100%; }

.sailing .page-header .grid-col-pic,
.environnement .page-header .grid-col-pic,
.kairlin .page-header .grid-col-pic {
  text-align: center; }

.kairlin .page-header .page-header__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -66%); }
  .kairlin .page-header .page-header__logo .svg-icon {
    color: #fff;
    height: 90px;
    width: 256px; }

.kairlin .page-header .page-header__title {
  font-size: 3em;
  margin: 0 auto;
  max-width: 800px;
  padding: 4rem 0; }
  .kairlin .page-header .page-header__title strong {
    display: inline; }

.kairlin .page-header .page-header__visual > figure > picture > img {
  min-height: 200px;
  object-fit: cover; }

/**
 * ==================== @ NAV SECONDARY
 */
.nav-secondary {
  background-color: #fff;
  padding: 1em 0;
  text-align: center; }
  .nav-secondary li {
    display: inline-block;
    padding: 0 1em; }
    .nav-secondary li a {
      display: block;
      color: #093e54;
      padding: 1em 0; }
      .nav-secondary li a:hover {
        color: #a15f30; }

/**
 * ==================== @ SECTIONS
 */
.section-title {
  color: #a15f30;
  font-size: 2em;
  line-height: .9;
  margin: 0 0 3rem 0; }
  .section-title strong {
    color: #093e54; }

.section-title__wrapper {
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem; }
  .section-title__wrapper .svg-icon {
    height: auto;
    width: 120px;
    position: relative;
    margin-left: -3rem;
    margin-top: -1rem; }
  .section-title__wrapper .section-title {
    margin-left: 0; }
  .section-title__wrapper.m--right .section-title {
    margin-left: auto;
    margin-right: 6rem; }
    .section-title__wrapper.m--right .section-title strong {
      margin-right: .5em; }

@media screen and (min-width: 64em) {
  .page-header .grid-col-txt {
    position: relative;
    padding-top: 3.6rem;
    z-index: 1; }
  .page-header .page-header__title {
    font-size: 4.9375em;
    margin-top: 4rem;
    margin-bottom: 3rem; }
    .page-header .page-header__title.m--overlap {
      width: 180%; }
    .page-header .page-header__title.m--page {
      font-size: 4em; }
  .page-header .page-header__caption {
    font-size: 1.625em; }
  .page-header .grid-col-txt cite {
    font-size: 2.0625em;
    line-height: 1.3;
    max-width: 380px;
    display: inline-block; }
  .page-header .grid-col-txt p {
    font-size: 1.25em;
    max-width: 380px; }
  .page-header .caption p {
    text-align: right;
    margin: 0; }
  .page-header .grid-col-pic {
    padding-left: 0; }
    .page-header .grid-col-pic img {
      width: 100%; }
  /* Nav secondary */
  .nav-secondary {
    font-size: 1.25em; }
  /* SECTIONS */
  .section-title {
    font-size: 4em;
    line-height: 4.75rem;
    margin: 0 0 3rem 0; }
    .section-title strong {
      display: block;
      margin-left: .5em; }
    .section-title.m--left {
      margin-left: -2rem; }
      .section-title.m--left strong {
        margin-left: 0; }
  .section-title__wrapper {
    margin-left: 4rem;
    margin-right: 4rem; }
    .section-title__wrapper .svg-icon {
      margin-left: -11rem;
      margin-top: 0;
      position: absolute;
      width: 250px; }
    .section-title__wrapper .section-title {
      font-size: 5.625em;
      margin-left: 6rem; }
    .section-title__wrapper.m--right {
      text-align: right;
      position: relative;
      right: 3%; }
      .section-title__wrapper.m--right .section-title {
        margin-left: auto;
        margin-right: 12rem; }
        .section-title__wrapper.m--right .section-title strong {
          margin-right: .5em; }
  .kairlin .page-header .page-header__title {
    font-size: 4em; } }

@media screen and (min-width: 80em) {
  .section-title__wrapper.m--right .section-title {
    margin-right: 6rem; }
  .section-title__wrapper.m--right .svg-icon {
    margin-left: -4rem; } }

/**
 * ==================== @ BREADCRUMB
*/
.breadcrumb {
  display: inline-block;
  width: 100%;
  margin-top: 20px; }

.breadcrumb__list {
  list-style: none;
  padding: 0; }
  .breadcrumb__list li {
    display: inline-block;
    font-size: 0.8125em;
    color: #989898;
    font-style: italic; }
  .breadcrumb__list li + li::before {
    content: "|";
    display: inline-block;
    color: #989898;
    font-style: normal;
    margin: 0 2px 0 8px; }
  .breadcrumb__list li:last-child {
    color: #a15f30;
    font-style: normal;
    font-weight: 600; }
  .breadcrumb__list li a {
    color: #989898; }

@media screen and (min-width: 64em) {
  .breadcrumb {
    margin-top: 0; } }

/**
 * ==================== @ BANNER
*/
.banner {
  color: #FFF;
  padding: 2em 0;
  position: relative;
  text-align: center; }
  .banner .svg-icon.material,
  .banner .svg-icon.waves {
    width: 100px;
    height: 80px; }
  .banner p {
    font-size: 1.375rem;
    margin: 0; }
  .banner span {
    font-size: 1.125rem; }
  .banner .bt-wrapper {
    margin-top: 1rem; }

@media screen and (min-width: 64em) {
  .banner {
    text-align: initial;
    padding-left: 8rem; }
    .banner .svg-icon.material,
    .banner .svg-icon.waves {
      position: absolute;
      left: -80px;
      top: 50%;
      transform: translatey(-50%); }
    .banner p {
      font-size: 2.0625rem;
      margin: 0 0 0 1em;
      max-width: 580px; }
    .banner span {
      font-size: 1.625rem; }
    .banner .btn-wrapper {
      position: relative;
      top: 50%;
      transform: translatey(-50%); } }

/**
 * ==================== @ POPUP GIE
*/
.popup-gie {
  display: none; }
  .popup-gie__bg {
    background-color: rgba(23, 49, 80, 0.96);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100; }
  .popup-gie__wrap {
    background-color: #fff;
    border: 2rem solid #173150;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: 102; }
    .popup-gie__wrap > div, .popup-gie__wrap figure {
      flex: 0 0 100%; }
  .popup-gie__logo img {
    max-height: 75px;
    width: auto; }
  .popup-gie__text {
    padding: 2rem 2rem 1rem 2rem; }
    .popup-gie__text > p {
      color: #a15f30;
      font-size: 1.125rem; }
  .popup-gie button {
    background-color: #a15f30;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 700;
    line-height: 34px;
    color: #fff;
    height: 32px;
    width: 32px;
    position: absolute;
    right: .25rem;
    top: .25rem;
    text-align: center;
    z-index: 2; }
  .popup-gie figure, .popup-gie img {
    display: block; }
  .alertgie .popup-gie {
    display: block; }

@media screen and (min-width: 40em) {
  .popup-gie__wrap {
    position: absolute;
    left: 50%;
    top: 50vh;
    transform: translate(-50%, -50%);
    z-index: 102; }
    .popup-gie__wrap > div, .popup-gie__wrap figure {
      flex: 0 0 50%; }
  .popup-gie__logo img {
    max-height: 100%; }
  .popup-gie__text {
    padding: 4rem 2rem 1rem 2rem; } }

@media screen and (min-width: 64em) {
  .popup-gie__wrap {
    max-width: 1024px; } }

/**
 * ==================== @ GENERIC FORMS
*/
.sv-form fieldset {
  border: none;
  padding: 0; }

.sv-form legend {
  font-size: 1.625em; }

.form-field {
  padding: .5em 0;
  margin-bottom: .5em; }

.form-label {
  display: block;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 0.8125em;
  padding: .2em 0; }
  .form-label.required:after {
    content: '*';
    margin-left: .5em;
    color: #a15f30; }

.form-field__input, .form-field__select, .form-field__text {
  border: 2px solid rgba(161, 95, 48, 0.5);
  border-radius: 4px;
  color: #093e54;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 1em;
  padding: .5em;
  width: 100%; }

.form-field__text {
  min-height: 6em; }

.m--fieldleft {
  padding-left: 0; }

.m--fieldright {
  padding-right: 0; }

.form-field__submit {
  background-color: #a15f30;
  border-radius: 1em;
  border: none;
  color: #fff;
  font-size: 1.25em;
  padding: .5em 1.2em; }
  .form-field__submit .svg-icon {
    font-size: 1.25em;
    margin-left: .5em;
    margin-top: -5px;
    vertical-align: middle; }

@media screen and (min-width: 64em) {
  fieldset {
    padding-top: 0.35em;
    padding-right: 0.625em;
    padding-bottom: 0.75em;
    padding-left: 0.625em; }
  .m--fieldleft {
    padding-left: 1rem; }
  .m--fieldright {
    padding-right: 1rem; } }

/**
 * ==================== @ BLOG
 */
.article-teaser {
  display: block;
  padding: 0 1rem 4rem 1rem;
  position: relative;
  text-align: left; }
  .article-teaser .icon-holder {
    position: absolute;
    z-index: 2;
    width: 4.5rem;
    height: 4.5rem; }
    .article-teaser .icon-holder:before {
      content: "";
      display: block;
      position: absolute;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      z-index: -1;
      background-color: #f7f4f4; }
    .article-teaser .icon-holder .svg-icon {
      vertical-align: initial;
      height: 100%; }
  .article-teaser .icon-holder.m--pattern-single,
  .article-teaser .icon-holder.m--pattern-single-gold {
    font-size: 4.5rem;
    left: -3rem;
    top: -2.5rem; }
    .article-teaser .icon-holder.m--pattern-single:before,
    .article-teaser .icon-holder.m--pattern-single-gold:before {
      top: 0.75rem;
      left: 1.75rem; }
  .article-teaser .icon-holder.m--pattern-duo {
    font-size: 4.1875rem;
    left: -1.5rem;
    top: -2.5rem; }
    .article-teaser .icon-holder.m--pattern-duo:before {
      left: -0.3125rem;
      top: initial;
      bottom: 0.1875rem; }
    .article-teaser .icon-holder.m--pattern-duo:after {
      content: "";
      display: block;
      position: absolute;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      z-index: -1;
      background-color: #f7f4f4;
      top: 0.1875rem;
      right: 0.0625rem; }
  .article-teaser .svg-icon.arrow-right {
    font-size: 3rem;
    color: #a15f30;
    border: 2px solid #a15f30;
    border-radius: 100%;
    padding: .5rem;
    position: absolute;
    bottom: -1rem;
    right: 2rem; }
  .article-teaser a {
    display: block;
    background-color: #FFF;
    padding: 1rem 2rem;
    height: 100%;
    position: relative; }
    .article-teaser a:hover h3 {
      text-decoration: underline; }
    .article-teaser a:hover .svg-icon.arrow-right {
      color: #FFF;
      background-color: #a15f30; }
  .article-teaser h3 {
    color: #093e54;
    line-height: 1.3;
    margin-top: 0; }
  .article-teaser .infos {
    color: #323232;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    margin: 0.3125rem 0 1rem;
    padding: 0;
    width: 100%; }
  .article-teaser .theme {
    font-weight: bold; }
  .article-teaser .date {
    font-style: italic;
    font-size: 0.8em; }

.article .page-header .date {
  color: #989898;
  font-style: italic;
  font-size: 0.8em;
  margin-bottom: 1rem;
  text-align: right; }

.article .page-header__title.m--page {
  margin-top: .5em; }

.article .text-wide .l-wrapper {
  max-width: 53.125em; }

.article h1:not(.page-header__title),
.article h2 {
  margin-top: -10px; }

.article h2 {
  font-size: 2em; }

.article h3 {
  font-size: 1.25em;
  color: #093e54; }

.article p {
  margin-top: 0; }

.article ul {
  line-height: 1.5em; }

.article .produit-pic {
  top: 4px; }

.separator {
  height: 4em; }

@media screen and (min-width: 80em) {
  .article-teaser {
    padding: 0 2.5rem 4rem 0; }
  .article .page-header .date {
    padding: 0 3rem; } }

/**
 * ==================== @ PAGINATION
*/
.pagination__list li {
  display: inline-block;
  vertical-align: middle; }
  .pagination__list li a, .pagination__list li span {
    color: #a15f30; }
    .pagination__list li a:hover, .pagination__list li span:hover {
      color: #093e54; }

.pagination__number {
  display: inline-block;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  padding: .5rem;
  width: 3rem;
  height: 2.625rem;
  line-height: 1.5rem; }
  .pagination__number .svg-icon {
    display: none; }
  .pagination__number[aria-current="page"] {
    color: #093e54; }
    .pagination__number[aria-current="page"] .svg-icon {
      display: block;
      font-size: 3rem;
      position: absolute;
      left: 0;
      bottom: 0; }

.pagination__prev,
.pagination__next {
  font-size: 2rem; }

.pagination__prev {
  margin-right: .5rem; }

.pagination__next {
  margin-left: .5rem; }

.pagination__prev .svg-icon {
  transform: scaleX(-1); }

/**
 * ==================== @ LIGHTBOX
 */
[data-lb-src] {
  cursor: pointer; }

.lightbox {
  background-color: rgba(255, 255, 255, 0.9);
  height: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: opacity 0.1s ease; }
  .lightbox::before {
    content: '🗙';
    cursor: pointer;
    font-size: 2em;
    position: absolute;
    right: 3%;
    top: 4%; }
  .lightbox img {
    position: absolute;
    left: 50%;
    max-height: 90%;
    max-width: 90%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .lightbox.is-open {
    height: 100%;
    opacity: 1; }

/**
 * ==================== @ HOME
 */
.home {
  /**
    * ==================== @ SECTIONS
    */ }
  .home .page-header .grid-layout {
    width: 100vw; }
  .home .page-header .page-header__title {
    font-size: 2.25em; }
  .home .page-header .grid-col-txt cite {
    font-size: 2rem; }
  .home .page-header .grid-col-pic {
    margin-left: -8px; }
    .home .page-header .grid-col-pic figure {
      max-height: 620px; }
  .home .edito {
    padding-bottom: 4rem; }
    .home .edito .section-title {
      position: relative;
      z-index: 4; }
    .home .edito p {
      font-size: 0.875em; }
  .home .low-consumption {
    background-color: #093e54;
    color: #fff;
    padding-bottom: 4em;
    position: relative; }
    .home .low-consumption .section-title {
      color: #e49258; }
      .home .low-consumption .section-title strong {
        color: #fff; }
    .home .low-consumption .section-sub-subtitle {
      color: #e49258;
      font-size: 1.25em;
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: .5em; }
    .home .low-consumption .grid-layout {
      margin-top: 2em; }
      .home .low-consumption .grid-layout div {
        padding-right: 1rem; }
      .home .low-consumption .grid-layout .bc-picto {
        position: relative;
        text-align: center;
        height: 172px;
        width: 172px; }
      .home .low-consumption .grid-layout h3 {
        margin-bottom: .2em; }
      .home .low-consumption .grid-layout .svg-icon {
        position: absolute; }
        .home .low-consumption .grid-layout .svg-icon.picto {
          color: #e49258;
          font-size: 4.5em;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
      .home .low-consumption .grid-layout .half-circle {
        font-size: 0;
        height: 134px;
        width: 134px; }
      .home .low-consumption .grid-layout .circle-left {
        color: #e49258;
        top: 0;
        left: 0; }
      .home .low-consumption .grid-layout .circle-right {
        color: #fff;
        right: 0;
        bottom: 0; }
    .home .low-consumption h3 {
      color: #e49258;
      font-size: 2.625em; }
    .home .low-consumption p {
      font-family: "Lato", Helvetica, Arial, sans-serif;
      font-size: 1em; }
  .home .latestnews {
    background-color: #f7f4f4;
    padding-top: 6rem;
    padding-bottom: 4rem; }

@media screen and (min-width: 64em) {
  .home {
    /**
     * ==================== @ SECTIONS
     */ }
    .home .page-header .grid-layout {
      width: auto; }
    .home .page-header .page-header__title {
      font-size: 4.9375em; }
    .home .edito .section-title {
      width: 150%; }
    .home .edito p {
      font-size: 1em !important; }
    .home .low-consumption {
      padding-top: 4rem; }
      .home .low-consumption .grid-layout div {
        padding-right: 4rem; }
    .home .latestnews {
      padding-bottom: 2rem; } }

/**
 * ==================== @ INNER PAGE INTRO
*/
.kairlin .intro .grid-layout {
  background-color: #fff;
  padding: 2rem 0; }

@media screen and (min-width: 64em) {
  .intro {
    margin-top: 4rem; } }

/**
 * ==================== @ Notre Histoire
*/
.history .history-timeline ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.history .history-timeline li {
  color: #093e54;
  font-size: 1em; }
  .history .history-timeline li em {
    font-style: normal;
    font-weight: 600;
    display: inline-block;
    margin: 1em 0 .5em 0;
    font-size: 1.25em; }
  .history .history-timeline li strong {
    color: #a15f30; }

.history .entry__title {
  color: #093e54;
  font-size: 1.25em; }

@media screen and (min-width: 64em) {
  .history {
    margin: 4em 0;
    padding-top: 4rem;
    /* .entry {
      max-width: 45%;
      p {
        margin-top: 0;
      }
    }

    .grid-col-txt:nth-child(2n+2) .entry {
      float: right;
    }

    .grid-col-txt:first-child,
    .grid-col-txt:last-child {
      .entry {
        float: none;
        max-width: 50%;
        margin: 0 auto;
        text-align: center;
        padding: .5em 0;
        margin-bottom: 2em;
      }
      .entry__title {
        color: $c-gold;
        font-size: em(33);
      }
      p {
        font-size: em(20);
        line-height: 1.6;
        margin: 0;
      }
    }*/ }
    .history .history-timeline li {
      clear: both;
      float: left;
      max-width: 45%;
      line-height: 1.6; }
      .history .history-timeline li:first-child, .history .history-timeline li:last-child {
        background-color: #f7f4f4;
        float: none;
        font-size: 1.25em;
        max-width: 50%;
        margin: 0 auto;
        padding: .5em 0 1em 0;
        text-align: center; }
        .history .history-timeline li:first-child em, .history .history-timeline li:last-child em {
          color: #a15f30;
          font-size: 1.75em; }
        .history .history-timeline li:first-child p, .history .history-timeline li:last-child p {
          font-size: 1.25em;
          line-height: 1.6; }
      .history .history-timeline li:nth-child(2n+2) {
        float: right; } }

/**
 * ==================== @ Notre équipe
*/
.our-team .gallery {
  background-color: #f7f4f4; }
  .our-team .gallery .grid-col-pic {
    margin-bottom: 2.5em;
    text-align: center;
    position: relative; }
    .our-team .gallery .grid-col-pic .picture-size span {
      display: none; }
  .our-team .gallery .member__photo {
    position: relative;
    display: inline-block; }
    .our-team .gallery .member__photo .svg-icon {
      font-size: 70px;
      position: absolute;
      top: 140px;
      right: -20px; }
  .our-team .gallery .gallery__title {
    color: #a15f30;
    font-size: 1.625em;
    margin: 1em 0 0 0; }
  .our-team .gallery p {
    margin-top: 0.3125em; }

@media screen and (min-width: 40em) {
  .our-team .gallery .grid-col-pic {
    text-align: initial; } }

/**
 * ==================== @ TRADES
*/
.trades {
  overflow: hidden;
  padding-top: 4rem; }
  .trades .grid-layout {
    padding-bottom: 3rem; }
    .trades .grid-layout.m--blue h3, .trades .grid-layout.m--blue .caption, .trades .grid-layout.m--blue .pattern-env2, .trades .grid-layout.m--blue .svg-icon, .trades .grid-layout.m--blue .chapo {
      color: #093e54; }
    .trades .grid-layout.m--gold h3, .trades .grid-layout.m--gold .caption, .trades .grid-layout.m--gold .pattern-env2, .trades .grid-layout.m--gold .svg-icon, .trades .grid-layout.m--gold .chapo {
      color: #a15f30; }
  .trades .section-background {
    position: absolute;
    left: -1rem;
    right: -1rem;
    top: 3rem;
    bottom: 0;
    z-index: -10; }
    .trades .section-background .section-background__inner {
      background-color: #fff;
      height: 100%; }
  .trades .grid-col__pattern {
    display: none;
    order: 3;
    padding: 4rem;
    position: relative;
    z-index: 4; }
    .trades .grid-col__pattern .pattern-duo {
      height: 200px;
      width: 236px;
      position: absolute;
      left: 50%;
      top: -100px;
      transform: translateX(-50%); }
  .trades .m--layout-2 .grid-col-txt {
    order: 1; }
  .trades .m--layout-2 .grid-col-pic {
    order: 2; }
  .trades .grid-col-txt h3 {
    font-size: 1.625em;
    line-height: 1.2;
    margin: .5em 0; }
  .trades .picture__wrapper {
    display: inline-block;
    position: relative; }
    .trades .picture__wrapper .pattern-env2, .trades .picture__wrapper .pattern-env1 {
      display: none; }
  .trades .picture__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2; }
    .trades .picture__logo .svg-icon {
      color: #fff !important;
      height: auto;
      width: 200px;
      transform: translate(-50%, -50%); }
  .trades .grid-col-pic.m--img {
    position: relative; }
    .trades .grid-col-pic.m--img img {
      width: 100%;
      height: auto; }
  .trades.m--founders .caption {
    line-height: 1.6; }
    .trades.m--founders .caption h3 {
      color: #a15f30;
      font-size: 1.25em; }

@media screen and (min-width: 48em) {
  .trades .m--layout-2 .grid-col-txt {
    order: 2; }
  .trades .m--layout-2 .grid-col-pic {
    order: 1; } }

@media screen and (min-width: 64em) {
  .trades figure.m--no-size {
    position: absolute;
    top: 0; }
  .trades .section-background {
    left: 1rem;
    right: 1rem;
    padding-left: 8.3333%;
    padding-right: 8.3333%; }
    .trades .section-background .section-background__inner {
      margin-left: 5rem;
      margin-right: 5rem; }
  .trades .grid-col-txt h3 {
    font-size: 3em;
    margin: .5em 0; }
  .trades .grid-col-pic {
    padding-left: 0;
    text-align: right; }
  .trades .picture__wrapper {
    height: 414px;
    width: 414px;
    overflow: hidden;
    border-radius: 50%; }
    .trades .picture__wrapper .pattern-env2, .trades .picture__wrapper .pattern-env1 {
      display: block; }
    .trades .picture__wrapper .svg-icon {
      display: inline-block;
      height: 414px;
      width: 414px; }
    .trades .picture__wrapper + .picture-size {
      position: relative;
      top: -2.5em;
      display: inline-block; }
    .trades .picture__wrapper figure {
      right: 5.2rem; }
  .trades figure {
    overflow: hidden; }
    .trades figure img {
      height: 414px;
      width: auto;
      max-width: none; }
  .trades .picture__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2; }
    .trades .picture__logo .svg-icon {
      color: #fff;
      height: auto;
      width: 200px;
      transform: translate(-50%, -50%); }
  .trades .m--layout-2 .grid-col-pic {
    text-align: left; }
    .trades .m--layout-2 .grid-col-pic figure {
      left: 5.2rem;
      right: 0; } }

@media screen and (min-width: 80em) {
  .trades.m--img-right figure {
    padding-left: 2.5rem; }
    .trades.m--img-right figure .picture-size {
      left: 2.5rem; }
  .trades.m--img-left figure {
    padding-right: 2.5rem; } }

/**
 * ==================== @ Nos Valeurs
*/
.values .grid-col-txt {
  padding-bottom: 2em; }
  .values .grid-col-txt h3 {
    color: #a15f30;
    font-size: 2em;
    line-height: 40px;
    max-width: 450px;
    margin-top: 1em; }

.values .values__svg {
  color: #a15f30;
  width: 170px;
  height: 170px; }
  .values .values__svg .svg-icon.half-circle-left,
  .values .values__svg .svg-icon.half-circle-right {
    font-size: 130px; }
  .values .values__svg .svg-icon.half-circle-right {
    position: relative;
    left: 40px;
    top: 40px; }
  .values .values__svg .svg-icon.full-circle-1,
  .values .values__svg .svg-icon.full-circle-2 {
    font-size: 170px; }

@media screen and (min-width: 64em) {
  .values {
    margin-top: 4rem; }
    .values .grid-layout {
      margin-top: 2em;
      margin-bottom: 2em; } }

/**
 * ==================== @ Explore
 */
@media screen and (min-width: 64em) {
  .explore .grid-layout {
    margin-top: 4rem;
    margin-bottom: 8rem; } }

/**
 * ==================== @ Nos Services
*/
.services .grid-col-txt {
  padding-bottom: 2em; }
  .services .grid-col-txt h3 {
    color: #a15f30;
    font-size: 2em;
    line-height: 1.2;
    max-width: 450px;
    margin-top: 1em; }

.services .grid-layout.m--blue .services__svg,
.services .grid-layout.m--blue .entry__title {
  color: #093e54; }

.services .grid-layout.m--gold .services__svg,
.services .grid-layout.m--gold .entry__title,
.services .grid-layout.m--gold .chapo {
  color: #a15f30; }

.services .services__svg {
  width: 170px;
  height: 170px; }
  .services .services__svg .svg-icon {
    font-size: 170px; }

@media screen and (min-width: 64em) {
  .services {
    margin-top: 4rem; }
    .services .grid-col-txt {
      padding-bottom: 4em; }
    .services .services__heading p {
      font-size: 1.25em;
      max-width: 500px; } }

/**
 * ==================== @ Projets
*/
.projects .grid-layout .grid-col-pic,
.projects .grid-layout .grid-col-txt {
  margin-bottom: 2rem; }

.projects .grid-layout .grid-col-pic {
  text-align: center; }

.projects .grid-layout .entry {
  color: #093e54; }

.projects .grid-layout .sv-u-1:nth-child(3) {
  order: 3; }

.projects .grid-layout .sv-u-1:nth-child(4) {
  order: 2; }

.projects .grid-layout .sv-u-1:nth-child(5) {
  order: 4; }

.projects .grid-layout .sv-u-1:nth-child(6) {
  order: 5; }

.projects i {
  color: #989898;
  display: block; }

.projects .projects__headblock p {
  margin: 0;
  color: #a15f30; }

.projects .projects__social {
  margin-top: 1rem; }
  .projects .projects__social li {
    display: inline-block;
    background-color: #093e54;
    border-radius: 100%;
    text-align: center;
    width: 36px;
    height: 36px;
    margin-right: 1rem; }
    .projects .projects__social li:hover {
      opacity: 0.5; }
    .projects .projects__social li .svg-icon {
      font-size: 1.5625rem;
      margin-top: 5px;
      padding: 2px;
      color: #FFF;
      vertical-align: middle; }
      .projects .projects__social li .svg-icon.linkedin {
        font-size: 1.375rem;
        margin-left: 2px; }

.projects .projects__tour {
  padding: 2em 0; }
  .projects .projects__tour .accordion {
    border-bottom: 2px solid #e49258;
    padding: 2rem 0 1rem 2rem; }
    .projects .projects__tour .accordion .entry {
      max-height: 0;
      overflow: hidden; }
    .projects .projects__tour .accordion.active .entry {
      max-height: 100%; }
    .projects .projects__tour .accordion.active .projects__btn {
      background-color: #a15f30;
      color: #FFF; }
      .projects .projects__tour .accordion.active .projects__btn .svg-icon {
        transform: rotate(180deg); }
  .projects .projects__tour .projects__title {
    font-size: 2.0625em;
    color: #093e54; }
  .projects .projects__tour .entry__title {
    color: #093e54; }
  .projects .projects__tour .entry p {
    max-width: 980px; }
  .projects .projects__tour .projects__btn {
    background-color: #f7f4f4;
    color: #a15f30;
    font-size: 0.75em;
    width: 40px;
    height: 40px;
    border: none;
    text-align: center;
    border-radius: 100%; }
  .projects .projects__tour a {
    text-decoration: underline;
    color: #a15f30; }
    .projects .projects__tour a.projects__link {
      float: right; }

@media screen and (min-width: 64em) {
  .projects {
    margin: 6rem 0; }
    .projects .section-title__wrapper {
      margin-bottom: 6rem; }
    .projects .grid-layout .grid-col-pic,
    .projects .grid-layout .grid-col-txt {
      margin-bottom: 8rem; }
    .projects .grid-layout .sv-u-1:nth-child(3),
    .projects .grid-layout .sv-u-1:nth-child(4),
    .projects .grid-layout .sv-u-1:nth-child(5),
    .projects .grid-layout .sv-u-1:nth-child(6) {
      order: initial; }
    .projects .projects__heading {
      margin: 1rem 0 2rem 0; }
    .projects .projects__headblock {
      display: inline-block;
      width: 49%; }
    .projects .projects__tour {
      padding: 0 2rem 6rem 2rem;
      /*accordion*/ }
      .projects .projects__tour .projects__title {
        font-size: 3.25em; }
      .projects .projects__tour .accordion .projects__heading {
        display: flex;
        position: relative;
        margin: 0; }
      .projects .projects__tour .accordion .projects__headblock:first-child {
        width: 13%; }
      .projects .projects__tour .accordion .projects__headblock:nth-child(2) {
        width: 20%; }
      .projects .projects__tour .accordion .projects__headblock:nth-child(3) {
        width: 33%; }
      .projects .projects__tour .accordion .projects__headblock:nth-child(4) {
        width: 33%; }
      .projects .projects__tour .accordion figure {
        float: left;
        margin-top: 1rem;
        margin-right: 1rem; }
      .projects .projects__tour .accordion .projects__btn {
        position: absolute;
        right: 0;
        top: 0; }
      .projects .projects__tour.m--env .projects__headblock:first-child {
        width: 22%; }
      .projects .projects__tour.m--env .projects__headblock:nth-child(2) {
        width: 33%; }
      .projects .projects__tour.m--env .projects__headblock:nth-child(2) {
        width: 33%; } }

/**
 * ==================== @ Nos Partenaires
*/
.partners {
  padding-bottom: 4rem; }
  .partners .entry__title {
    margin-top: 2rem;
    color: #093e54; }
  .partners .btn-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .partners .picture-size {
    display: none; }
  .partners .grid-col-pic {
    text-align: center; }

.meet-us {
  margin-top: 8em;
  background-color: #fff;
  padding-bottom: 4rem; }
  .meet-us .grid-layout {
    background-color: #fff;
    padding-top: 2rem;
    position: relative; }
    .meet-us .grid-layout .entry {
      color: #fff;
      font-size: 1.625em; }
  .meet-us .grid-col-left {
    background-color: #093e54;
    padding: 1rem 1rem 6rem 1rem; }
  .meet-us .shape-solo + .grid-col-left {
    padding: 6rem 1rem 1rem 1rem; }
  .meet-us .grid-col-right {
    background-color: #a15f30;
    padding: 6rem 1rem 1rem 1rem; }
  .meet-us .tease {
    font-size: 1em;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-style: italic;
    color: #989898; }
  .meet-us .shape-duo {
    background: transparent;
    position: relative; }
    .meet-us .shape-duo figure {
      position: absolute;
      left: 50%;
      bottom: -18rem;
      transform: translateX(-50%); }
  .meet-us .shape {
    display: none; }

.meet-us.m--page {
  margin-top: 0;
  padding-top: 4rem; }
  .meet-us.m--page p, .meet-us.m--page span {
    display: block;
    margin: 0; }
  .meet-us.m--page .shape-solo {
    position: absolute;
    top: -2rem; }

@media screen and (min-width: 64em) {
  .meet-us {
    padding-bottom: 4rem; }
    .meet-us .grid-layout {
      padding: 2rem; }
      .meet-us .grid-layout .entry {
        color: #fff;
        font-size: 1.625em; }
    .meet-us .grid-col-left {
      padding: 6rem 9rem 6rem 5rem; }
    .meet-us .grid-col-right {
      padding: 6rem 2rem 6rem 9rem; }
    .meet-us .tease {
      font-size: 1.25em; }
    .meet-us .shape-duo {
      background: transparent;
      position: relative; }
      .meet-us .shape-duo figure {
        position: absolute;
        bottom: -16.5rem; }
    .meet-us .shape {
      display: block;
      position: absolute;
      height: 414px;
      width: 414px; }
      .meet-us .shape .svg-icon {
        height: 414px;
        width: 414px; }
      .meet-us .shape.pattern1 {
        color: #093e54;
        left: 1rem;
        top: -11%; }
      .meet-us .shape.pattern2 {
        color: #a15f30;
        right: 1rem;
        bottom: 20%; }
  .meet-us.m--page .shape.pattern1 {
    top: -15%; }
  .meet-us.m--page .shape.pattern2 {
    bottom: 5%; }
  .meet-us.m--page .shape-solo {
    position: static; }
    .meet-us.m--page .shape-solo figure {
      position: absolute;
      right: 10%;
      top: 50%;
      transform: translatey(-50%); } }

/**
 * ==================== @ KAIRLIN USAGES
*/
.kairlin-use {
  padding: 4rem 0; }
  .kairlin-use .grid-layout .slide {
    padding: 1.2rem; }
  .kairlin-use .grid-layout .slide-caption {
    color: #093e54;
    font-size: 14px;
    margin-top: 0.5em; }

/**
 * ==================== @ CONTACT FORM
*/
.contact-form {
  background-color: #fff;
  padding: 2rem 0; }
  .contact-form .section-title {
    color: #093e54;
    font-size: 3.25em; }
  .contact-form .grid-col-txt {
    padding-left: 0;
    padding-bottom: 4rem; }
    .contact-form .grid-col-txt h4 {
      font-size: 1.25em;
      line-height: 1.6;
      margin-top: 0; }
    .contact-form .grid-col-txt strong {
      color: #a15f30; }
    .contact-form .grid-col-txt li {
      margin-bottom: 1rem; }
      .contact-form .grid-col-txt li .svg-icon {
        color: #a15f30;
        font-size: 2em;
        margin-right: .5em; }
      .contact-form .grid-col-txt li a {
        text-decoration: underline; }

@media screen and (min-width: 48em) {
  .contact-form .grid-col-txt {
    padding-right: 10rem; } }

.text-wide {
  padding: 1.5em 0; }
  .text-wide.m--bg-white, .text-wide.m--bg-blue {
    padding: 4em 0; }

.text-2cols.m--bg-white, .text-wide.m--bg-white {
  background-color: #fff; }

.text-2cols.m--bg-blue, .text-wide.m--bg-blue {
  background-color: #093e54;
  color: #fff; }
  .text-2cols.m--bg-blue h3, .text-wide.m--bg-blue h3 {
    color: #FFF; }

.img-wide {
  text-align: center; }

.text-2cols {
  padding-bottom: 4em; }
  .text-2cols.m--bg-white, .text-2cols.m--bg-blue {
    margin: 3em 0;
    padding: 2em 0; }

.text-img {
  background-color: #FFF;
  margin: 4em 0; }

.quote {
  text-align: center;
  padding: 3em 0;
  background-color: #093e54;
  position: relative;
  z-index: -2; }
  .quote .entry {
    color: #FFF;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-size: 2em;
    font-style: italic;
    max-width: 780px;
    margin: 0 auto; }
    .quote .entry p:first-child {
      margin: 0; }
  .quote .author {
    color: #093e54;
    font-size: 1em; }
    .quote .author em {
      color: #a15f30;
      font-family: "Lato", Helvetica, Arial, sans-serif;
      font-style: italic; }

@media screen and (min-width: 64em) {
  .text-wide {
    background-color: #f7f4f4; }
  .text-img.m--img-left .grid-col-txt {
    order: 1; }
  .text-img.m--img-left .grid-col-pic {
    order: 0;
    position: relative; }
    .text-img.m--img-left .grid-col-pic figure {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 2rem; }
  .quote {
    z-index: initial;
    padding: 1em 0 0;
    margin: 4em 0; }
    .quote:before, .quote:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      z-index: -2;
      height: 4em;
      width: 100%;
      background-color: #093e54; }
    .quote:before {
      top: -4em; }
    .quote:after {
      bottom: -4em; } }

/**
 * ==================== @ Really ?!
 */
.pattern-sail,
.pattern-sail2 {
  color: #093e54; }

.pattern-env {
  color: #a15f30; }

.timeline-streak {
  height: 2rem;
  position: relative;
  z-index: -1; }
  .timeline-streak .shape {
    background-color: rgba(228, 146, 88, 0.5);
    width: 2px;
    font-size: 0;
    position: absolute;
    left: 2%; }

.produit-pic {
  border: none !important; }
  .produit-pic img {
    height: auto !important; }

@media screen and (min-width: 64em) {
  .timeline-streak {
    height: 4rem; }
    .timeline-streak .shape {
      left: 50%; }
    .timeline-streak + section {
      margin-top: 0 !important; } }
