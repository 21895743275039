/**
 * ==================== @ Explore
 */

@media screen and (min-width:$bp-l) {

  .explore {
    .grid-layout {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }
  }

}
