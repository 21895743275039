
.footer {
  background-color: $c-blue;
  padding-bottom: 4em;
}

.footer-baseline {
  background-color: $c-grey-lite;
  padding-left: 1rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  position: relative;

  .website-eco-mention {
    color: $c-grey;
    padding-left: 1rem;
    position: absolute;
    left: 0;
    top: 1rem;
  }

  .grid-col-txt {
    font-size: em(14);
    line-height: 1.6;
    padding: .5rem 0;

    span {
      color: $c-grey;
      display: block;
      font-size: em(13);
      font-family: $font-txt;
      font-style: italic;
    }

    strong {
      color: $c-gold;
    }

    .svg-icon {
      color: $c-gold;
      font-size: em(48);
      float: left;
      margin-right: 1rem;
    }
  }
}

.footer-nav {
  color: #fff;
  padding-top: 2em;

  h3 {
    color: $c-sand;
    display: none;
    font-size: em(20);
    margin: 2em 0 1.3em;
  }
}

.footer-menu {
  display: none;

  li {
    display: block;
    margin-bottom: .5em;

    a {
      font-size: em(16);
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer-nav__rs {
  padding-top: 2rem;
  padding-left: 1rem;
  text-align: center;

  .kairos-rs {
    color: rgba(#fff,.2);
    font-size: em(72);
  }

  p {
    font-size: em(13);
    font-family: $font-txt;
  }

  > a:hover {
    text-decoration: underline;
  }

  li {
    display: inline-block;

    + li {
      margin-left: 0.4em;
    }

    a {
      background-color: #fff;
      border-radius: 50%;
      color: $c-gold;
      display: block;
      line-height: 28px;
      text-align: center;
      height: 28px;
      width: 28px;

      &:hover {
        background-color: $c-gold;
        color: #fff;
      }
    }

    .svg-icon {
      font-size: 1em;
    }
  }
}

@media screen and (min-width:$bp-l) {

  .footer-baseline {
    padding-left: 20%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;

    .website-eco-mention {
      padding-left: 2rem;
      position: absolute;
      left: 0;
      top: auto;
      span {
        display: block;
      }
    }

    .grid-col-txt {
      font-size: em(16);
      padding: 0;

      .svg-icon {
        margin-right: 1.2rem;
      }
    }
  }

  .footer-nav__rs {
    text-align: left;
  }

  .footer-nav {
    h3 {
      display: block;
    }
  }

  .footer-menu {
    display: block;
  }
}
