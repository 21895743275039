

.grid-layout {

  .grid-col-txt {
    padding-left: $cols-padding;
    padding-right: $cols-padding;

    .chapo {
      font-size: 26px;
      line-height: 1.4;
    }
  }

}

.offset-left {
  margin-left: 0;
}


@media screen and (min-width:$bp-m) {

  .grid-layout {
    margin: 0 auto;

    .grid-col-txt {
      padding-left: $cols-padding-m;
      padding-right: $cols-padding-m;
    }

    .grid-col-pic {
      padding-left: 1em;
    }

    .our-partners & {
      max-width: 640px;
      text-align: center;
    }
  }

  .offset-left {
    margin-left: $grid-offset;
  }

  .offset-sides {
    margin-left: $grid-offset;
    margin-right: $grid-offset;
  }

  .outset-sides {
    margin-left: $grid-outset;
    margin-right: $grid-outset;
  }
}
