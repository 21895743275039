/**
 * ==================== @ Projets
*/

.projects {

  .grid-layout {

    .grid-col-pic,
    .grid-col-txt {
      margin-bottom: 2rem;
    }

    .grid-col-pic {
      text-align: center;
    }

    .entry {
      color: $c-blue;
    }

    .sv-u-1:nth-child(3) {
      order: 3;
    }

    .sv-u-1:nth-child(4) {
      order: 2;
    }

    .sv-u-1:nth-child(5) {
      order: 4;
    }

    .sv-u-1:nth-child(6) {
      order: 5;
    }

  }

  i {
    color: $c-grey;
    display: block;
  }

  .projects__headblock {
    p {
      margin: 0;
      color: $c-gold;
    }
  }

  .projects__social {
    margin-top: 1rem;

    li {
      display: inline-block;
      background-color: $c-blue;
      border-radius: 100%;
      text-align: center;
      width: 36px;
      height: 36px;
      margin-right:1rem;

      &:hover {
        opacity: 0.5;
      }

      .svg-icon {
        font-size: rem(25);
        margin-top: 5px;
        padding: 2px;
        color: #FFF;
        vertical-align: middle;
        &.linkedin {
          font-size: rem(22);
          margin-left: 2px;
        }
      }
    }
  }

  .projects__tour {
    padding: 2em 0;

    .accordion {
      border-bottom: 2px solid $c-sand;
      padding: 2rem 0 1rem 2rem;
      .entry {
        max-height: 0;
        overflow: hidden;
      }
      &.active .entry {
        max-height: 100%;
      }
      &.active .projects__btn {
        background-color: $c-gold;
        color: #FFF;
        .svg-icon {
          transform: rotate(180deg);
        }
      }
    }

    .projects__title {
      font-size: em(33);
      color: $c-blue;
    }

    .entry__title {
      color: $c-blue;
    }

    .entry p {
      max-width: 980px;
    }

    .projects__btn {
      background-color: $c-grey-lite;
      color: $c-gold;
      font-size: em(12);
      width: 40px;
      height: 40px;
      border: none;
      text-align: center;
      border-radius: 100%;
    }

    a {
      text-decoration: underline;
      color: $c-gold;
      &.projects__link {
        float: right;
      }
    }
  }

}

@media screen and (min-width:$bp-l) {

  .projects {
    margin: 6rem 0;

    .section-title__wrapper {
      margin-bottom: 6rem;
    }

    .grid-layout {

      .grid-col-pic,
      .grid-col-txt {
        margin-bottom: 8rem;
      }

      .sv-u-1:nth-child(3),
      .sv-u-1:nth-child(4),
      .sv-u-1:nth-child(5),
      .sv-u-1:nth-child(6) {
        order: initial;
      }

    }

    .projects__heading {
      margin: 1rem 0 2rem 0;
    }

    .projects__headblock {
      display: inline-block;
      width: 49%;
    }

    .projects__tour {
      padding: 0 2rem 6rem 2rem;
      .projects__title {
        font-size: em(52);
      }
      .accordion {

        .projects__heading {
          display: flex;
          position: relative;
          margin: 0;
        }

        .projects__headblock:first-child {
          width: 13%;
        }
        .projects__headblock:nth-child(2) {
          width: 20%;
        }
        .projects__headblock:nth-child(3) {
          width: 33%;
        }
        .projects__headblock:nth-child(4) {
          width: 33%;
        }

        figure {
          float: left;
          margin-top: 1rem;
          margin-right: 1rem;
        }

        .projects__btn {
          position: absolute;
          right: 0;
          top: 0;
        }

      } /*accordion*/

      &.m--env {
        .projects__headblock:first-child {
          width: 22%;
        }
        .projects__headblock:nth-child(2) {
          width: 33%;
        }
        .projects__headblock:nth-child(2) {
          width: 33%;
        }
      }

    }

  }

}
