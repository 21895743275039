/**
 * ==================== @ POPUP GIE
*/

.popup-gie {
	display: none;

	&__bg {
		background-color: rgba(23,49,80, .96);
		position: absolute;
		left: 0; right: 0;
		top: 0; bottom: 0;
		z-index: 100;
	}

	&__wrap {
		background-color: #fff;
		border: 2rem solid #173150;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		width: 100%;
		z-index: 102;

		> div, figure {
			flex: 0 0 100%;
		} 
	}

	&__logo img {
		max-height: 75px;
		width: auto;
	}

	&__text {
		padding: 2rem 2rem 1rem 2rem;

		> p{
			color: $c-gold;
			font-size: rem(18);
		}
	}

	button {
		background-color: $c-gold;
		border-radius: 50%;
		font-size: 18px;
		font-weight: 700;
		line-height: 34px;
		color: #fff;
		height: 32px;
		width: 32px;
		position: absolute;
		right: .25rem;
		top: .25rem;
		text-align: center;
		z-index: 2;
	}
	

	figure, img {
		display: block;
	}

	.alertgie & {
		display: block;
	}
}

@media screen and (min-width:$bp-s) {

  .popup-gie {

		&__wrap {
			position: absolute;
			left: 50%;
			top: 50vh;
			transform: translate(-50%, -50%);
			z-index: 102;
	
			> div, figure {
				flex: 0 0 50%;
			}
		}

		&__logo img {
			max-height: 100%;
		}
	
		&__text {
			padding: 4rem 2rem 1rem 2rem;
		}
  }
}

@media screen and (min-width:$bp-l) {
	.popup-gie {
		&__wrap {
			max-width: 1024px;
		}
	}
}
