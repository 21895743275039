/**
 * ==================== @ Nos Valeurs
*/

.values {

  .grid-col-txt {
    padding-bottom: 2em;

		h3 {
			color: $c-gold;
			font-size: em(32);
			line-height: 40px;
			max-width: 450px;
			margin-top: 1em;
		}
  }

  .values__svg {
    color: $c-gold;
    width: 170px;
    height: 170px;

    .svg-icon.half-circle-left,
    .svg-icon.half-circle-right {
      font-size: 130px;
    }

    .svg-icon.half-circle-right {
      position: relative;
      left: 40px;
      top: 40px;
    }

    .svg-icon.full-circle-1,
    .svg-icon.full-circle-2 {
      font-size: 170px;
    }
  }

}

@media screen and (min-width:$bp-l) {

  .values {
    margin-top: 4rem;

    .grid-layout {
      margin-top: 2em;
      margin-bottom: 2em;
    }

  }

}
