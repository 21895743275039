/**
 * ==================== @ Nos Partenaires
*/

.partners {
  padding-bottom: 4rem;

  .entry__title {
    margin-top: 2rem;
    color: $c-blue;
  }

  .btn-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .picture-size {
    display: none;
  }
  .grid-col-pic {
    text-align: center;
  }
}
