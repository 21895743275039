.text-wide {
  padding: 1.5em 0;
  &.m--bg-white,
  &.m--bg-blue {
    padding: 4em 0;
  }
}

.text-2cols, .text-wide {
  &.m--bg-white {
    background-color: #fff;
  }

  &.m--bg-blue {
    background-color: $c-blue;
    color: #fff;
    h3 {
      color: #FFF;
    }
  }
}

.img-wide {
  text-align: center;
}

.text-2cols {
  padding-bottom: 4em;
  &.m--bg-white,
  &.m--bg-blue {
    margin: 3em 0;
    padding: 2em 0;
  }
}

.text-img {
  background-color: #FFF;
  margin: 4em 0;
}

.quote {
  text-align: center;
  padding: 3em 0;
  background-color: $c-blue;
  position: relative;
  z-index:-2;

  .entry {
    color: #FFF;
    font-family: $font-txt;
    font-size: 2em;
    font-style: italic;
    max-width: 780px;
    margin: 0 auto;

    p:first-child {
      margin: 0;
    }
  }

  .author {
    color: $c-blue;
    font-size: 1em;

    em {
      color: $c-gold;
      font-family: $font-txt;
      font-style: italic;
    }
  }
}

@media screen and (min-width:$bp-l) {

  .text-wide {
    background-color: $c-grey-lite;
  }

  .text-img.m--img-left {
    .grid-col-txt {
      order: 1;
    }

    .grid-col-pic {
      order: 0;
      position: relative;
      figure {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 2rem;
      }
    }
  }

  .quote {
    z-index: initial;
    padding: 1em 0 0;
    margin: 4em 0;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      z-index: -2;
      height: 4em;
      width: 100%;
      background-color: $c-blue;
    }

    &:before {
      top: -4em;
    }

    &:after {
      bottom: -4em;
    }
  }

}
