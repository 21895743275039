/**
 * ==================== @ INNER PAGE INTRO
*/

.kairlin .intro {
	
	.grid-layout {
		background-color: #fff;	
		padding: 2rem 0;	
	}
}

@media screen and (min-width:$bp-l) {

  .intro {
    margin-top: 4rem;
  }

}